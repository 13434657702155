const promptColumnSet = new Set([
  'c.input-expansion-prompt-template',
  'cdr.prompt',
  'cdr.evidence-classification-prompt',
  'lr.prompt',
  'lr.evidence-classification-prompt',
  'c.prompt',
]);

const setConfigSheetStyles = (sheet) => {
  const row = sheet.getRow(4);

  row.eachCell((cell, colNumber) => {
    const columnKey = sheet.getColumn(colNumber).key;

    if (promptColumnSet.has(columnKey)) {
      // eslint-disable-next-line no-param-reassign
      cell.alignment = { wrapText: true };
    } else {
      // eslint-disable-next-line no-param-reassign
      cell.alignment = { vertical: 'top' };
    }
  });
};

export default setConfigSheetStyles;
