import React from 'react';

import { DialogContent } from '@mui/material';

import { Dialog } from 'components/UIKit';

const FiltersMobileDialog = ({ isOpen, filters, onClose }) => (
  <Dialog fullScreen open={isOpen} withCloseButton onClose={onClose}>
    <DialogContent sx={{ p: 0 }}>
      {filters}
    </DialogContent>
  </Dialog>
);

export default FiltersMobileDialog;
