import React from 'react';

import AdvisorPageContent from './AdvisorPageContent';

import useAnswer from './hooks/useAdvisorAnswer/useAdvisorAnswer';

const AdviserPage = () => {
  const { isSavedAnswerLoading, answerSet, onSubmit } = useAnswer();

  return (
    <AdvisorPageContent
      pageTitle="Advisor"
      isLoading={isSavedAnswerLoading}
      answerSet={answerSet}
      onSubmit={onSubmit}
    />
  );
};

export default AdviserPage;
