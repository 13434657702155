import React from 'react';

import AppWrapper from 'components/AppWrapper';

import { LinearProgress } from '@mui/material';

import DecisionPreviewModal from 'components/DecisionPreviewModal';
import AdviserSearchBar from 'components/AdvisorSearchBar';

import AdvisorAnswer from './AdvisorAnswerSet';

import useStyles from './AdvisorPageContent.styles';

const AdvisorPageContent = ({
  pageTitle, isLoading, answerSet, laws, decisions, sourceMap, onSubmit,
}) => {
  const classes = useStyles();

  if (isLoading) {
    return <LinearProgress />;
  }

  const handleSearch = (val) => {
    onSubmit(val);
  };

  return (
    <AppWrapper title={pageTitle}>
      <div style={{ maxWidth: 768, margin: 'auto' }}>
        <AdviserSearchBar
          className={classes.searchBar}
          textFieldClassName={classes.searchBarTextField}
          defaultValue={answerSet?.request.question}
          onSearch={handleSearch}
        />
        {answerSet && (
        <AdvisorAnswer
          key={answerSet.id}
          answerSet={answerSet}
          laws={laws}
          decisions={decisions}
          sourceMap={sourceMap}
        />
        )}
        <DecisionPreviewModal />
      </div>
    </AppWrapper>
  );
};

export default AdvisorPageContent;
