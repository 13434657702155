import React from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  LinearProgress, Tabs, Tab, FormControl, InputLabel, Select, MenuItem,
} from '@mui/material';

import LawSectionCard from 'components/LawSectionCard';

import useAnswerDebug from './hooks/useAnswerDebug/useAnswerDebug';
import getFilteredLaws from './utils/getFilteredLaws';

import useStyles from './AsyncAnswerDebug.styles';

const filterSteps = {
  NONE: 'none',
  VECTOR_SEARCH: 'vector-search',
  VECTOR_SEARCH_CUTOFF: 'vector-search-cutoff',
  EVIDENCE_CLASSIFICATION_CUTOFF: 'evidence-classification-cutoff',
};

const AsyncAnswerDebugPage = () => {
  const classes = useStyles();
  const answerInfo = useAnswerDebug();
  const [searchParams, setSearchParams] = useSearchParams();

  if (!answerInfo) {
    return <LinearProgress />;
  }

  const { answerSet } = answerInfo;

  const filterStep = searchParams.get('filter_step') || 'none';
  const laws = getFilteredLaws(answerInfo.laws, filterStep);

  const selectedLaw = laws.find(({ id }) => id === searchParams.get('law_id')) || laws[0];
  const selectedSourceId = selectedLaw?.id;

  const handleTabChange = (e, newValue) => {
    searchParams.set('law_id', newValue);
    setSearchParams(searchParams);
  };

  const handleFilterStepChange = (event) => {
    searchParams.set('filter_step', event.target.value);
    setSearchParams(searchParams);
  };

  const maxLaws = answerSet.request.law_rules.top_results_amount;

  return (
    <div>
      <div>{answerSet.request.question}</div>
      <Tabs value="laws">
        <Tab value="laws" label="Laws" />
        <Tab value="decisions" label="Court decisions" disabled />
      </Tabs>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tabs
          textColor="secondary"
          value={selectedSourceId}
          TabIndicatorProps={{ sx: { display: 'none' } }}
          sx={{
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap',
            },
          }}
          onChange={handleTabChange}
        >
          {laws.map((law, index) => (
            <Tab
              key={law.id}
              value={law.id}
              label={`$ ${law.number}`}
              sx={{ color: index > maxLaws - 1 ? 'red' : undefined }}
            />
          ))}
        </Tabs>
        <FormControl sx={{ width: 260 }}>
          <InputLabel id="demo-simple-select-label">Filter</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterStep}
            onChange={handleFilterStepChange}
          >
            <MenuItem value={filterSteps.NONE}>None</MenuItem>
            <MenuItem value={filterSteps.VECTOR_SEARCH}>Vector search</MenuItem>
            <MenuItem value={filterSteps.VECTOR_SEARCH_CUTOFF}>Vector search cutoff</MenuItem>
            <MenuItem value={filterSteps.EVIDENCE_CLASSIFICATION_CUTOFF}>Evidence classification cutoff</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: 16 }}>
        {selectedLaw && (
          <LawSectionCard
            {...selectedLaw}
            law={selectedLaw}
            paragraphTextClassName={classes.paragraph}
            withDebugInfo
          />
        )}
      </div>
    </div>
  );
};

export default AsyncAnswerDebugPage;
