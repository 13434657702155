import React, { useState, Fragment } from 'react';

import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import SearchConfigDialog from 'pages/SearchPage/SettingsButton/SearchConfigDialog';

function SettingsButton() {
  const [isDialogOpened, setDialogOpened] = useState(false);

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  return (
    <>
      <IconButton sx={{ color: 'white' }} onClick={() => setDialogOpened(true)}>
        <SettingsIcon />
      </IconButton>
      <SearchConfigDialog open={isDialogOpened} onClose={handleDialogClose} />
    </>
  );
}

export default SettingsButton;
