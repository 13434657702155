import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    paddingBottom: 8,
    position: 'relative',
  },
  courtLogo: {
    flexShrink: 0,
    width: 40,
    height: 45,
    marginRight: 10,
    marginTop: 4,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  caseNumberWrapper: {
    display: 'flex',
    paddingRight: 80,
  },
  caseNumber: {
    fontWeight: 700,
  },
  linkButton: {
    marginLeft: 8,
    marginTop: -5,
    marginBottom: -5,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 8,
  },
  date: {
    marginTop: 4,
    fontSize: 12,
    color: '#70757a',
  },
});

export default useStyles;
