import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormGroup, FormControlLabel, Checkbox,
} from '@mui/material';

const CheckboxGroupController = ({
  control,
  name,
  options,
  ...otherProps
}) => (
  <FormGroup {...otherProps}>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value = [] } }) => (
        <>
          {options.map((fruitOption) => (
            <FormControlLabel
              key={fruitOption.value}
              label={fruitOption.label}
              control={(
                <Checkbox
                  value={fruitOption.value}
                  checked={value.some(
                    (existingValue) => existingValue === fruitOption.value,
                  )}
                  onChange={(event, checked) => {
                    if (checked) {
                      onChange([
                        ...value,
                        event.target.value,
                      ]);
                    } else {
                      onChange(
                        value.filter(
                          (val) => val !== event.target.value,
                        ),
                      );
                    }
                  }}
                />
              )}
            />
          ))}
        </>
      )}
    />
  </FormGroup>
);

export default CheckboxGroupController;
