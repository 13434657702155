import { useState, useEffect } from 'react';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

async function fetchCourtDecisionById(id) {
  try {
    const response = await request({
      method: 'get',
      url: `/finhound-service/court-decisions/${id}`,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error');
  }
}

const getParagraphs = (ids, paragraphs) => {
  const res = [];

  ids.forEach((id) => {
    const paragraph = paragraphs.find((p) => p.id === id);

    if (paragraph) {
      res.push(paragraph);
    }
  });

  return res;
};

const useAdviserAnswerCourtDecisions = (courtDecisions) => {
  const [items, setItems] = useState(null);

  const fetchCourtDecisions = async () => {
    try {
      const decisions = await Promise.all(courtDecisions.map(({ id }) => fetchCourtDecisionById(id)));

      decisions.forEach((decision, index) => {
        // eslint-disable-next-line no-param-reassign
        decision.paragraphs = getParagraphs(courtDecisions[index].paragraphs_ids, decision.paragraphs);
        // eslint-disable-next-line no-param-reassign
        decision.court_decision_id = decision.id;
      });

      setItems(decisions);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useEffect(() => {
    if (courtDecisions?.length > 0) {
      fetchCourtDecisions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courtDecisions?.length]);

  return {
    items,
  };
};

export default useAdviserAnswerCourtDecisions;
