import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Tabs, Tab } from '@mui/material';

import AdminPageWrapper from 'components/AdminPageWrapper';

import TestBenchAnswerSetsTabContent from './TestBenchAnswerSetsTabContent';
import TestBenchEvidenceClassificationTabContent from './TestBenchEvidenceClassificationTabContent';

const AnswerSetTestBenchmarkPage = () => {
  const { tab } = useParams();
  const navigate = useNavigate();

  const activeTab = tab || 'answer-sets';

  const handleTabChange = (e, newValue) => {
    navigate(`/admin/test-bench/${newValue}`);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'answer-sets':
        return <TestBenchAnswerSetsTabContent />;
      case 'evidence-classification':
        return <TestBenchEvidenceClassificationTabContent />;
      default:
        return <TestBenchAnswerSetsTabContent />;
    }
  };

  return (
    <AdminPageWrapper>
      <Tabs
        sx={{ mb: 2 }}
        value={activeTab}
        onChange={handleTabChange}
      >
        <Tab value="answer-sets" label="Answer sets" />
        <Tab value="evidence-classification" label="Evidence classification" />
      </Tabs>
      {renderTabContent()}
    </AdminPageWrapper>
  );
};

export default AnswerSetTestBenchmarkPage;
