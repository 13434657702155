import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  progressWrapper: {
    position: 'relative',
    zIndex: 2,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  header: {
    height: 48,
    display: 'flex',
  },
  actions: {
    marginLeft: 'auto',

    '& > *': {
      marginLeft: 8,
    },
  },
  appBarPlaceholder: {
    height: 71,
  },
  appBar: {
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100%',
  },
});

export default useStyles;
