import React from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import AppsIcon from '@mui/icons-material/Apps';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useIsAdmin } from 'hooks';

export default function AppsMenu({ sx }) {
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchClick = () => {
    navigate('/');
    handleClose();
  };

  const handleAdminClick = () => {
    navigate('/admin');
    handleClose();
  };

  const handleAdviserClick = () => {
    navigate('/advisor');
    handleClose();
  };

  const handleAdviserExperimentalClick = () => {
    navigate('/advisor-experimental');
    handleClose();
  };

  return (
    <div>
      <IconButton sx={{ color: 'white', mr: '12px', ...sx }} onClick={handleClick}>
        <AppsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSearchClick}>
          <ListItemIcon>
            <SearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Search</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAdviserClick}>
          <ListItemIcon>
            <HelpOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Advisor</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleAdviserExperimentalClick}>
          <ListItemIcon>
            <HelpOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Advisor experimental</ListItemText>
        </MenuItem>
        <MenuItem disabled onClick={handleClose}>
          <ListItemIcon>
            <QuestionAnswerIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Chat</ListItemText>
        </MenuItem>
        {isAdmin && <Divider />}
        {isAdmin && (
          <MenuItem onClick={handleAdminClick}>
            <ListItemIcon>
              <AdminPanelSettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Admin</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
