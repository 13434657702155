import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AppWrapper from 'components/AppWrapper';
import SearchBar from 'components/SearchBar';
import { useDidMount, useIsAdmin, useWillUnmount } from 'hooks';

import { facetsActions, searchActions } from 'redux/actions';

import { searchSelectors as selectors } from 'redux/selectors';

import { LinearProgress, IconButton } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import DecisionPreviewModal from 'components/DecisionPreviewModal';

import { useMobile } from 'breakpoints';

import useUrlParamsUpdate from './useUrlParamsUpdate';

import SettingsButton from './SettingsButton';
import Filters from './Filters';

import SearchResults from './SearchResults';

import useStyles from './SearchPage.styles';

const SearchPage = () => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const isInitialized = useSelector(selectors.isInitializedSelector);
  const isFiltersOpen = useSelector(selectors.isFiltersOpenSelector);
  const isMobile = useMobile();

  const handleFiltersClick = () => {
    dispatch(searchActions.setIsFiltersOpen({ value: !isFiltersOpen }));
  };

  const handleFiltersClose = () => {
    dispatch(searchActions.setIsFiltersOpen({ value: false }));
  };

  useDidMount(() => {
    const searchResultId = searchParams.get('sr');

    dispatch(searchActions.init({ searchResultId }));
    dispatch(facetsActions.getFacetsRequest({ selectAll: !searchResultId }));

    if (!isMobile) {
      dispatch(searchActions.setIsFiltersOpen({ value: true }));
    }
  });

  useWillUnmount(() => {
    dispatch(searchActions.reset());
  });

  useUrlParamsUpdate();

  if (!isInitialized) {
    return <LinearProgress />;
  }

  return (
    <AppWrapper
      contentClassName={classes.pageContent}
      isFiltersOpen={isFiltersOpen}
      title="Search"
      filters={<Filters />}
      headerContent={(
        <>
          <SearchBar className={classes.searchBar} />
          <IconButton sx={{ color: 'white' }} onClick={handleFiltersClick}><FilterAltIcon /></IconButton>
          {isAdmin && <SettingsButton />}
        </>
      )}
      onFiltersClose={handleFiltersClose}
    >
      <SearchResults />
      <DecisionPreviewModal />
    </AppWrapper>
  );
};

export default SearchPage;
