import { useDidMount } from 'hooks';
import { useState } from 'react';
import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

async function fetchFeedbacks(subjectType, subjectIds) {
  try {
    const response = await request({
      method: 'post',
      url: '/finhound-service/feedbacks/_search',
      data: {
        subject_type: subjectType,
        subject_ids: subjectIds,
      },
    });
    return response.data.items;
  } catch (error) {
    throw new Error('Error');
  }
}

const getFeedbackStr = (feedbacks) => {
  const ratings = feedbacks.map(({ rating }) => rating);

  const min = Math.min(...ratings);
  const max = Math.max(...ratings);

  if (min === max) {
    return min;
  }

  return `${min} - ${max}`;
};

const useSearchAdminResult = ({ searchResultId }) => {
  const [searchResult, setSearchResult] = useState(null);
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line consistent-return
  const fetchSearchResult = async () => {
    setLoading(true);
    try {
      const { data } = await request({
        method: 'get',
        url: `/finhound-service/court-decision-searches/${searchResultId}`,
      });

      const promise = fetchFeedbacks('COURT_DECISION_SEARCH_RESPONSE', [data.id]);
      const promiseItems = fetchFeedbacks('COURT_DECISION_SEARCH_RESPONSE_ITEM', data.items.map(({ id }) => id));

      const [resultFeedbacks, resultItemsFeedback] = await Promise.all([promise, promiseItems]);

      data.feedbacks = resultFeedbacks;
      data.feedbacksStr = getFeedbackStr(data.feedbacks);

      data.items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.feedbacks = resultItemsFeedback.filter((feedback) => feedback.subject_id === item.id);
        // eslint-disable-next-line no-param-reassign
        item.feedbacksStr = getFeedbackStr(item.feedbacks);
      });

      setSearchResult(data);
    } catch (e) {
      NM.error('Something went wrong');
    }
    setLoading(false);
  };

  useDidMount(() => {
    fetchSearchResult();
  });

  return {
    searchResult,
    loading,
  };
};

export default useSearchAdminResult;
