const transformAnswerDebugLaws = (laws, answer) => {
  const scoresByParagraphId = {};

  answer.score_processing_info.law_based_search_result_scores.forEach(({ paragraphs }) => {
    paragraphs.forEach((paragraph) => {
      scoresByParagraphId[paragraph.id] = paragraph;
    });
  });

  answer.score_processing_info.law_based_evidence_classifier_scores.forEach(({ paragraphs }) => {
    paragraphs.forEach((paragraph) => {
      scoresByParagraphId[paragraph.id].evidence_score = paragraph.evidence_score;
    });
  });

  const getDebugInfo = (paragraphId) => {
    const debugInfo = scoresByParagraphId[paragraphId] || {};

    const approved0 = debugInfo.db_score > answer.request.law_rules.cut_off_score;

    return {
      ...debugInfo,
      approved0,
      approved1: approved0 && debugInfo.evidence_score,
      approved2: (debugInfo.evidence_score || 0) > answer.request.law_rules.evidence_cut_off_score,
    };
  };

  laws.forEach((law) => {
    law.sections.forEach((section) => {
      section.additions.forEach((addition) => {
        addition.paragraphs.forEach((paragraph) => {
          // eslint-disable-next-line no-param-reassign
          paragraph.debugInfo = getDebugInfo(paragraph.id);

          // console.log(scoresByParagraphId[paragraph.id]);
        });
      });

      section.paragraphs.forEach((paragraph) => {
        // eslint-disable-next-line no-param-reassign
        paragraph.debugInfo = getDebugInfo(paragraph.id);
      });
    });

    law.attachments.forEach((attachment) => {
      attachment.paragraphs.forEach((paragraph) => {
        // eslint-disable-next-line no-param-reassign
        paragraph.debugInfo = getDebugInfo(paragraph.id);
      });
    });
  });
};

export default transformAnswerDebugLaws;
