import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mui/material';

const withAuthRequired = (WrappedComponent) => (props) => {
  const { orgId } = useParams();
  const [searchParams] = useSearchParams();
  const isInvitationLink = searchParams.get('invitation');

  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const hasAccess = !isInvitationLink && isAuthenticated && (!orgId || orgId === user.org_id);

  useEffect(() => {
    if (isLoading || hasAccess) {
      return;
    }
    (async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.pathname + (isInvitationLink ? '' : window.location.search),
        },
      });
    })();
  }, [
    isInvitationLink,
    hasAccess,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  ]);

  return hasAccess ? <WrappedComponent {...props} /> : <LinearProgress />;
};

export default withAuthRequired;
