import React, { useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import throttle from 'lodash.throttle';

import AppSearchBar from 'components/UIKit/SearchBar';

import request from 'api/request';

import { searchActions as actions, decisionActions } from 'redux/actions';

import { searchSelectors as selectors } from 'redux/selectors';

function removeDuplicates(arr) {
  return [...new Set(arr)];
}

function SearchBar({
  className, onSearch, onSuggestionSelect,
}) {
  const dispatch = useDispatch();

  const defaultValue = useSelector(selectors.searchTermSelector);

  const [value, setValue] = useState(defaultValue);
  const valueRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);

  const handleKeyDown = (e) => {
    if (document.querySelector('.MuiAutocomplete-option.Mui-focused')) {
      return;
    }

    if (e.keyCode === 13 || e.code === 'Enter') {
      e.target.blur();
      dispatch(actions.searchRequest({ searchTerm: value }));
      onSearch();
      setSuggestions([]);
      dispatch(decisionActions.reset());
    } else if (e.keyCode === 27 || e.code === 'Escape') {
      setValue('');
      setSuggestions([]);
    }
  };

  const searchSuggestions = useCallback(async (searchTerm) => {
    try {
      const { data: { items } } = await request({
        method: 'post',
        url: '/finhound-service/court-decision-searches/suggestions',
        data: {
          search_term: searchTerm,
        },
      });
      setSuggestions(items);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [setSuggestions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSearchSuggestions = useCallback(throttle(searchSuggestions, 1000), []);

  const handleInputChange = (e, newInputValue, reason) => {
    setValue(newInputValue);
    valueRef.current = newInputValue;

    if (reason === 'input') {
      if (!newInputValue) {
        setSuggestions([]);
      } else {
        throttledSearchSuggestions(newInputValue);
      }
    }
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
    valueRef.current = newValue;
  };

  const handleClose = (e, reason) => {
    if (reason === 'selectOption') {
      const newValue = valueRef.current;

      const option = suggestions.find(({ text }) => text === newValue);

      if (option) {
        onSuggestionSelect();
        dispatch(actions.searchByDecisionIdRequest({
          id: option.court_decision_id,
        }));
      }
      setValue(newValue);
    }
  };

  const handleHighlightChange = (event, option, reason) => {
    if (option && reason === 'keyboard') {
      setValue(option);
    }
  };

  const handleClear = () => {
    setValue('');
    setSuggestions([]);
  };

  return (
    <AppSearchBar
      className={className}
      value={value}
      options={removeDuplicates(suggestions.map((option) => option.text))}
      filterOptions={(x) => x}
      placeholder="Search..."
      onClose={handleClose}
      onHighlightChange={handleHighlightChange}
      onInputChange={handleInputChange}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onClear={handleClear}
    />
  );
}

SearchBar.defaultProps = {
  onSearch: () => {},
  onSuggestionSelect: () => {},
};

export default SearchBar;
