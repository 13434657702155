import React from 'react';

import { Typography } from '@mui/material';

import MarkdownLib from 'markdown-to-jsx';
import SourceReference from './SourceReference/SourceReference';

const Reference = ({ referenceId, sourceMap }) => {
  const reference = sourceMap[referenceId];

  if (!reference) {
    return `[${referenceId}]`;
  }

  return <SourceReference referenceId={referenceId} source={sourceMap[referenceId]} />;
};

const Markdown = ({ text, sourceMap }) => (
  <Typography>
    <MarkdownLib
      options={{
        overrides: {
          Reference: {
            component: Reference,
            props: {
              sourceMap,
            },
          },
        },
      }}
    >
      {text.replaceAll(/\[(L|C)(\d{2})\]/g, '<Reference referenceId="$1$2" />')}
    </MarkdownLib>
  </Typography>
);

export default Markdown;
