import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';

import { actions } from 'redux/submittedFeedbacks/submittedFeedbacksReducer';
import * as userSelectors from 'redux/user/userSelectors';
import request from '../utils/sagas/request';

export function* loadFeedbacksForSearchResultFlow({ payload: { searchResult } }) {
  const userId = yield select(userSelectors.idSelector);
  try {
    const [{ data: { items: items1 } }, { data: { items: items2 } }] = yield all([
      request({
        method: 'post',
        url: '/finhound-service/feedbacks/_search',
        data: {
          subject_type: 'COURT_DECISION_SEARCH_RESPONSE',
          subject_ids: [searchResult.id],
        },
      }),
      request({
        method: 'post',
        url: '/finhound-service/feedbacks/_search',
        data: {
          subject_type: 'COURT_DECISION_SEARCH_RESPONSE_ITEM',
          subject_ids: searchResult.items.map(({ id }) => id),
        },
      }),
    ]);

    const subjectIds = {};
    const feedbacks = [...items1, ...items2];

    feedbacks.forEach((feedback) => {
      if (feedback.creator_id === userId) {
        subjectIds[feedback.subject_id] = feedback;
      }
    });

    yield put(actions.loadFeedbacksForSearchResultSuccess({ subjectIds }));
  } catch (e) {
    NM.error('Error while fetching feedbacks');
  }
}

export function* loadFeedbacksForAnswerSetFlow({ payload: { answerSet } }) {
  const userId = yield select(userSelectors.idSelector);
  try {
    const [{ data: { items: items1 } }, { data: { items: items2 } }, { data: { items: items3 } }] = yield all([
      request({
        method: 'post',
        url: '/finhound-service/feedbacks/_search',
        data: {
          subject_type: 'ADVISOR_ANSWER_SET',
          subject_ids: [answerSet.id],
        },
      }),
      request({
        method: 'post',
        url: '/finhound-service/feedbacks/_search',
        data: {
          subject_type: 'ADVISOR_COURT_DECISION_BASED_ANSWER',
          subject_ids: [answerSet.court_decision_based_answer.id],
        },
      }),
      request({
        method: 'post',
        url: '/finhound-service/feedbacks/_search',
        data: {
          subject_type: 'ADVISOR_LAW_BASED_ANSWER',
          subject_ids: [answerSet.law_based_answer.id],
        },
      }),
    ]);

    const subjectIds = {};
    const feedbacks = [...items1, ...items2, ...items3];

    feedbacks.forEach((feedback) => {
      if (feedback.creator_id === userId) {
        subjectIds[feedback.subject_id] = feedback;
      }
    });

    yield put(actions.loadFeedbacksForAnswerSetSuccess({ subjectIds }));
  } catch (e) {
    NM.error('Error while fetching feedbacks');
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.loadFeedbacksForSearchResultRequest.type, loadFeedbacksForSearchResultFlow),
    takeLatest(actions.loadFeedbacksForAnswerSet.type, loadFeedbacksForAnswerSetFlow),
  ]);
}
