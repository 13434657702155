import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  progressWrapper: {
    position: 'relative',
    zIndex: 2,
  },
  progress: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
  },
  header: {
    height: 48,
    display: 'flex',
  },
  actions: {
    marginLeft: 'auto',

    '& > *': {
      marginLeft: 8,
    },
  },
  appBar: {
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
  },
  toolbar: {
    display: 'block',
    paddingTop: '8px',
    paddingBottom: '8px',

    '&&': {
      minHeight: 0,
    },
  },
  contentWrapper: {
    flexGrow: 1,
    display: 'flex',
    height: 'calc(100% - 64px)',
  },
  content: {
    padding: 16,
    overflow: 'auto',
    width: '100%',
  },
});

export default useStyles;
