import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
  },
  paragraphText: {
    fontSize: 14,
  },
  accordionTitle: {
    fontWeight: 500,
  },
  languageSwitcher: {
    marginLeft: 8,
  },
});

export default useStyles;
