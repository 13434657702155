/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

export const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setIsGlobalLoading: (state, { payload: isLoading }) => {
      state.isLoading = isLoading;
    },
  },
});

export const { setIsGlobalLoading } = slice.actions;
export const { actions } = slice;

export default slice.reducer;
