/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import useStyles from './LanguageSwitcher.styles';

const LanguageSwitcher = ({ value, onChange, ...otherProps }) => {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    onChange(event, newValue || value);
  };

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={value}
      onChange={handleChange}
      {...otherProps}
    >
      <ToggleButton className={classes.toggleButton} value="en">EN</ToggleButton>
      <ToggleButton className={classes.toggleButton} value="de">DE</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default LanguageSwitcher;
