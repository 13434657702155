const getConfigsFromSheet = (sheet) => {
  const res = [];

  sheet.eachRow((row, i) => {
    if (i >= 2) {
      if (!row.getCell('A').value) {
        // eslint-disable-next-line no-param-reassign
        row.getCell('A').value = i - 1;
      }

      const config = {
        id: row.getCell('A').value,
        promptTemplate: row.getCell('B').value,
      };

      res.push(config);
    }
  });

  return res;
};

export default getConfigsFromSheet;
