import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Menu, MenuItem, Avatar,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import useHasBillingAccess from 'hooks/useHasSubscriptionAccess';

const AvatarMenu = ({ sx, ...otherProps }) => {
  const hasBillingAccess = useHasBillingAccess();
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (e: MouseEvent) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleLogoutClick = () => {
    handleClose();
    logout({ returnTo: window.location.origin });
  };

  const handleBillingClick = () => {
    navigate('/billing');
  };

  return (
    <>
      <Avatar
        {...otherProps}
        sx={{
          border: 'none', cursor: 'pointer', padding: 0, ...sx,
        }}
        src={user.picture}
        component="button"
        onClick={handleClick}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {hasBillingAccess && (
          <MenuItem onClick={handleBillingClick}>Billing</MenuItem>
        )}
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default AvatarMenu;
