import { useEffect } from 'react';
import useState from 'react-usestateref';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import { setIsGlobalLoading } from 'redux/actions';

import { getAnswersSettings } from 'utils/answersSettings';

import isRequestBodyValid from './utils/isRequestBodyValid';

const completedStatusSet = new Set(['COMPLETE', 'PARTIAL_SUCCESS']);

const getCourtDecisionRules = ({
  mongoCutOffScore, pineconeCutOffScore, engine, tempPromptTemplate, ...otherRules
}) => ({
  ...otherRules,
  engine,
  cut_off_score: engine === 'MONGO' ? mongoCutOffScore : pineconeCutOffScore,
  prompt_template: tempPromptTemplate,
});

const getLawRules = ({
  mongoCutOffScore, pineconeCutOffScore, engine, tempPromptTemplate, ...otherRules
}) => ({
  ...otherRules,
  engine,
  cut_off_score: engine === 'MONGO' ? mongoCutOffScore : pineconeCutOffScore,
  prompt_template: tempPromptTemplate,
  include_interpretations: false,
  include_guidelines: false,
  include_exemptions: false,
});

let prevSearchRequest = null;

async function createAnswer({ body }) {
  try {
    const { data } = await request({
      method: 'post',
      url: '/finhound-service/async-answers',
      data: body,
    });
    return data;
  } catch (error) {
    throw new Error('Error');
  }
}

async function fetchAnswerSetById(id) {
  try {
    const { data } = await request({
      method: 'get',
      url: `/finhound-service/async-answers/${id}`,
    });
    return data;
  } catch (error) {
    throw new Error('Error');
  }
}

async function generateSummary(answerId) {
  try {
    const { data } = await request({
      method: 'post',
      url: `/finhound-service/async-answers/${answerId}/_generate_summary`,
      data: {},
    });
    return data;
  } catch (error) {
    throw new Error('Error');
  }
}

const useAnswer = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [answer, setAnswer, answerSetRef] = useState(null);
  const [isSavedAnswerLoading, setIsSavedAnswerLoading] = useState(false);
  const [isGeneratingSummary, setIsGeneratingSummary, isGeneratingSummaryRef] = useState(false);

  const answerId = answer?.id;

  const fetchAnswer = async (id) => {
    setIsSavedAnswerLoading(true);
    try {
      const answerSet = await fetchAnswerSetById(id);

      setAnswer(answerSet);
      setIsSavedAnswerLoading(false);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  const searchParamsAnswerId = searchParams.get('a');

  useEffect(() => {
    if (searchParamsAnswerId && searchParamsAnswerId !== answerId) {
      fetchAnswer(searchParamsAnswerId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamsAnswerId, answerId]);

  const pollAnswer = async (id) => {
    try {
      const answerSet = await fetchAnswerSetById(id);

      if (completedStatusSet.has(answerSet.status)) {
        generateSummary(answerSet.id);
        setIsGeneratingSummary(true);
      }

      if ((answerSet.status === 'ERROR' || answerSet.status === 'PARTIAL_SUCCESS')) {
        NM.error('Something went wrong');
      }

      if (answerSet.status === 'ERROR') {
        dispatch(setIsGlobalLoading(false));
      }

      setAnswer(answerSet);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const pollAnswerSummary = async (id) => {
    try {
      const answerSet = await fetchAnswerSetById(id);

      if (answerSet.summary) {
        dispatch(setIsGlobalLoading(false));
        setIsGeneratingSummary(false);

        setAnswer(answerSet);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    setIsGeneratingSummary(false);
    const intervalId = setInterval(() => {
      if (answerSetRef.current?.status === 'IN_PROGRESS') {
        pollAnswer(answerSetRef.current.id);
      } else if (isGeneratingSummaryRef.current) {
        pollAnswerSummary(answerSetRef.current.id);
      }
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerSetRef.current?.id]);

  const handleSubmit = async (question) => {
    const answerSettings = getAnswersSettings();

    const requestBody = {
      question,
      temperature: 0,
      input_expansion_on: answerSettings.input_expansion_on,
      input_expansion_prompt_template: answerSettings.input_expansion_prompt_template,
      summary_prompt_model: answerSettings.summary_prompt_model,
      prompt_template: answerSettings.prompt_template,
      law_rules: getLawRules(answerSettings.lawRules),
      court_decision_rules: getCourtDecisionRules(answerSettings.courtDecisionRules),
    };

    if (isRequestBodyValid(requestBody)) {
      const stringifiedSearchRequest = JSON.stringify(requestBody);

      if (prevSearchRequest === stringifiedSearchRequest || question.length < 10) {
        return;
      }

      dispatch(setIsGlobalLoading(true));

      prevSearchRequest = stringifiedSearchRequest;

      try {
        const answerSet = await createAnswer({ body: requestBody });

        setAnswer(answerSet);
        searchParams.set('a', answerSet.id);
        setSearchParams(searchParams);
      } catch (e) {
        prevSearchRequest = null;
        NM.error('Something went wrong');
      }
    } else {
      NM.error('Config is not valid');
    }
  };

  const getAnswerSet = () => {
    if (!answer) {
      return answer;
    }

    return {
      ...answer,
      isGeneratingSummary,
      status: isGeneratingSummary ? 'IN_PROGRESS' : answer.status,
    };
  };

  return {
    isSavedAnswerLoading,
    answerSet: getAnswerSet(),
    onSubmit: handleSubmit,
  };
};

export default useAnswer;
