import PropTypes from 'prop-types';
import withAuth0Provider from 'hocs/withAuth0Provider';
import withAuthRequired from 'hocs/withAuthRequired';
import withAccessTokenRequired from 'hocs/withAccessTokenRequired';
import withSubscriptionRequired from 'hocs/withSubscriptionRequired';

const PrivateRoute = ({ children }) => children;

PrivateRoute.propTypes = {
  children: PropTypes.any,
};

PrivateRoute.defaultProps = {
  children: undefined,
};

export default withAuth0Provider(withAuthRequired(withAccessTokenRequired(withSubscriptionRequired(PrivateRoute))));
