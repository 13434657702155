import React from 'react';

import withAuth0Provider from 'hocs/withAuth0Provider';
import withAuthRequired from 'hocs/withAuthRequired';
import withAccessTokenRequired from 'hocs/withAccessTokenRequired';

import { NotificationManager as NM } from 'react-notifications';

import { LinearProgress } from '@mui/material';
import { useDidMount } from 'hooks';

import request from 'api/request';

const BillingPage = () => {
  const openBillingPage = async () => {
    try {
      const { data: { url } } = await request({
        method: 'post',
        url: 'https://us-central1-finhound-dev.cloudfunctions.net/create-billing-session',
      });
      window.location.href = url;
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useDidMount(() => {
    openBillingPage();
  });

  return <LinearProgress />;
};

export default withAuth0Provider(withAuthRequired(withAccessTokenRequired(BillingPage)));
