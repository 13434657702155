import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioGroup } from '@mui/material';

const RadioGroupController = ({
  control,
  name,
  label,
  helperText,
  rules,
  children,
  ...otherProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <RadioGroup
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...otherProps}
      >
        {children}
      </RadioGroup>
    )}
    rules={rules}
  />
);

export default RadioGroupController;
