import React from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';
import BillingPage from 'pages/BillingPage';
import SearchPage from 'pages/SearchPage';
import AdvisorPage from 'pages/AdvisorPage';
import AdvisorAsyncPage from 'pages/AdvisorAsyncPage';

import SearchResultList from 'pages/admin/SearchListPage';
import AnswerSetListPage from 'pages/admin/AnswerSetListPage';
import AsyncAnswerListPage from 'pages/admin/AsyncAnswerListPage';
import TestBenchPage from 'pages/admin/TestBenchPage';
import LawsViewerPage from 'pages/admin/LawsViewerPage';
import AsyncAnswerDebugPage from 'pages/admin/AsyncAnswerDebugPage';

import PrivateRoute from './PrivateRoute';

const privateRoutes = [
  {
    path: '/',
    element: <SearchPage />,
  },
  {
    path: '/advisor',
    element: <AdvisorPage />,
  },
  {
    path: '/advisor-experimental',
    element: <AdvisorAsyncPage />,
  },
  {
    path: '/admin/searches',
    element: <SearchResultList />,
  },
  {
    path: '/admin/answer-sets',
    element: <AnswerSetListPage />,
  },
  {
    path: '/admin/async-answers',
    element: <AsyncAnswerListPage />,
  },
  {
    path: '/admin/test-bench/:tab?',
    element: <TestBenchPage />,
  },
  {
    path: 'admin/laws',
    element: <LawsViewerPage />,
  },
  {
    path: '/admin/async-answers/:id/debug',
    element: <AsyncAnswerDebugPage />,
  },
];

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/admin" element={<Navigate to="/admin/test-bench" replace />} />
        <Route
          path="/billing"
          element={<BillingPage />}
        />
        {privateRoutes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ))}
      </Routes>
    </Router>
  );
}

export default AppRouter;
