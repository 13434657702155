import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { searchSelectors as selectors } from 'redux/selectors';

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchResult = useSelector(selectors.searchResultSelector);
  const searchResultId = useSelector(selectors.searchResultsIdSelector);

  useEffect(() => {
    if (searchResultId) {
      searchParams.set('sr', searchResultId);
    } else if (searchResult) {
      searchParams.delete('sr');
    }
    setSearchParams(searchParams);
  }, [searchResultId, searchParams, setSearchParams, searchResult]);
};
