import { useState, useEffect } from 'react';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

async function fetchCourtDecisionById(id) {
  try {
    const response = await request({
      method: 'get',
      url: `/finhound-service/court-decisions/${id}`,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error');
  }
}

const getDecisions = (answerSet) => {
  const res = [];

  answerSet.court_decision_based_answers.forEach((answer) => {
    res.push(...answer.court_decisions);
  });

  return res;
};

const useAdviserAnswerCourtDecisions = (answerSet) => {
  const [items, setItems] = useState(null);

  const fetchCourtDecisions = async () => {
    try {
      const decisions = await Promise.all(
        getDecisions(answerSet).map((decision) => fetchCourtDecisionById(decision.id)),
      );

      decisions.forEach((decision) => {
        // eslint-disable-next-line no-param-reassign
        decision.court_decision_id = decision.id;
        // eslint-disable-next-line no-param-reassign
        decision.paragraphs = [];
      });

      setItems(decisions);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useEffect(() => {
    if (answerSet?.court_decision_based_answers?.length > 0) {
      fetchCourtDecisions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerSet?.id, answerSet?.court_decision_based_answers?.length]);

  useEffect(() => {
    setItems(null);
  }, [answerSet?.id]);

  return items;
};

export default useAdviserAnswerCourtDecisions;
