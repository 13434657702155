import React from 'react';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { Auth0Provider } from '@auth0/auth0-react';
import { useDidMount } from 'hooks';
import { request } from 'api';

const queryParams = qs.parse(window.location.search);

const withAuth0Provider = (WrappedComponent) => (props) => {
  const navigate = useNavigate();

  const pingService = async () => {
    try {
      await request({
        method: 'get',
        url: '/finhound-service/health',
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useDidMount(() => {
    pingService();
  });

  const onRedirectCallback = (appState) => {
    const returnTo = appState?.returnTo;

    if (returnTo) {
      navigate(returnTo, { replace: true });
    }
  };

  if (queryParams.error) {
    return <div style={{ padding: 8 }}>{queryParams.error}</div>;
  }

  return (
    <Auth0Provider
      domain="dev-jwh6lf6hsejgcbsx.us.auth0.com"
      clientId="YpqmmxFv7ObC0VYgPcSUAimqbxPWSCB1"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: 'YpqmmxFv7ObC0VYgPcSUAimqbxPWSCB1',
        invitation: queryParams.invitation || undefined,
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <WrappedComponent {...props} />
    </Auth0Provider>
  );
};

export default withAuth0Provider;
