const getRequestBody = (config, questions) => ({
  model: 'gpt-3.5-turbo-instruct',
  prompt: questions.map(({ question, evidence }) => config.promptTemplate
    .replaceAll('<text>', evidence)
    .replaceAll('<question>', question)),
  max_tokens: 1,
  logprobs: 2,
  temperature: 0,
  logit_bias: { 9891: 100, 2201: 100 },
});

export default getRequestBody;
