import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import { setIsGlobalLoading, submittedFeedbacksActions } from 'redux/actions';

import { getAnswersSettings } from 'utils/answersSettings';

import isRequestBodyValid from './utils/isRequestBodyValid';

const getCourtDecisionRules = ({
  mongoCutOffScore, pineconeCutOffScore, engine, tempPromptTemplate, ...otherRules
}) => ({
  ...otherRules,
  engine,
  cut_off_score: engine === 'MONGO' ? mongoCutOffScore : pineconeCutOffScore,
  prompt_template: tempPromptTemplate,
});

const getLawRules = ({
  mongoCutOffScore, pineconeCutOffScore, engine, tempPromptTemplate, ...otherRules
}) => ({
  ...otherRules,
  engine,
  cut_off_score: engine === 'MONGO' ? mongoCutOffScore : pineconeCutOffScore,
  prompt_template: tempPromptTemplate,
  include_interpretations: false,
  include_guidelines: false,
  include_exemptions: false,
});

let prevSearchRequest = null;

async function createAnswer({ body }) {
  try {
    const { data } = await request({
      method: 'post',
      url: '/finhound-service/answers',
      data: body,
    });
    return data;
  } catch (error) {
    throw new Error('Error');
  }
}

async function fetchAnswerSetById(id) {
  try {
    const { data } = await request({
      method: 'get',
      url: `/finhound-service/answer-sets/${id}`,
    });
    return data;
  } catch (error) {
    throw new Error('Error');
  }
}

const useAnswer = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [answer, setAnswer] = useState(null);
  const [isSavedAnswerLoading, setIsSavedAnswerLoading] = useState(false);

  const answerId = answer?.id;
  const searchParamsAnswerId = searchParams.get('a');

  const fetchAnswer = async (id) => {
    setIsSavedAnswerLoading(true);
    try {
      const answerSet = await fetchAnswerSetById(id);

      setAnswer(answerSet);
      dispatch(submittedFeedbacksActions.loadFeedbacksForAnswerSet({ answerSet }));
      setIsSavedAnswerLoading(false);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useEffect(() => {
    if (searchParamsAnswerId && searchParamsAnswerId !== answerId) {
      fetchAnswer(searchParamsAnswerId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamsAnswerId, answerId]);

  const handleSubmit = async (question) => {
    const { courtDecisionRules, lawRules } = getAnswersSettings();

    const requestBody = {
      question,
      temperature: 0,
      law_rules: getLawRules(lawRules),
      court_decision_rules: getCourtDecisionRules(courtDecisionRules),
    };

    if (isRequestBodyValid(requestBody)) {
      const stringifiedSearchRequest = JSON.stringify(requestBody);

      if (prevSearchRequest === stringifiedSearchRequest || question.length < 10) {
        return;
      }

      dispatch(setIsGlobalLoading(true));

      prevSearchRequest = stringifiedSearchRequest;

      try {
        const answerSet = await createAnswer({ body: requestBody });

        setAnswer(answerSet);

        searchParams.set('a', answerSet.id);
        setSearchParams(searchParams);
      } catch (e) {
        prevSearchRequest = null;
        NM.error('Something went wrong');
      }
    } else {
      NM.error('Config is not valid');
    }

    dispatch(setIsGlobalLoading(false));
  };

  return {
    isSavedAnswerLoading,
    answerSet: answer,
    onSubmit: handleSubmit,
  };
};

export default useAnswer;
