import React from 'react';

import {
  Accordion, AccordionSummary, AccordionDetails, Typography, LinearProgress, Box, Stack, Button,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SearchResultsCard from 'components/SearchResultsCard';

import LawSectionCard from 'components/LawSectionCard';
import ResultFeedback from 'components/ResultFeedback';

import { useIsAdmin } from 'hooks';

import AdvisorAnswers from './AdvisorAnswers';
import ViewAsyncAnswerRequestConfig from './ViewAsyncAnswerRequestConfig';

import useStyles from './AdvisorAnswerSet.styles';
import AdvisorResultStatus from './AdvisorResultStatus';

const AdvisorAnswerSet = ({
  answerSet, laws, decisions, sourceMap,
}) => {
  const classes = useStyles();
  const isAdmin = useIsAdmin();

  const renderDecisions = () => {
    if (!decisions) {
      return <LinearProgress />;
    }

    return decisions.map((decision) => (
      <div key={decision.id} style={{ position: 'relative', marginBottom: 16 }}>
        <div id={decision.id} style={{ position: 'absolute', marginTop: -20 }} />
        <SearchResultsCard
          headerProps={{ withReactions: false }}
          searchResult={decision}
          summaryDefaultExpanded
        />
      </div>
    ));
  };

  const renderLawSections = () => {
    if (!laws) {
      return <LinearProgress />;
    }

    return laws.map((law) => (
      <div key={law.id} style={{ position: 'relative', marginBottom: 16 }}>
        <div id={law.id} style={{ position: 'absolute', marginTop: -20 }} />
        <LawSectionCard
          {...law}
        />
      </div>
    ));
  };

  const handleDebugClick = () => {
    window.open(
      `/admin/async-answers/${answerSet.id}/debug`,
      '_blank',
    );
  };

  const renderHeader = () => {
    if (answerSet.status === 'IN_PROGRESS') {
      return <AdvisorResultStatus answerSet={answerSet} />;
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <ResultFeedback sx={{ mb: 0 }} subjectType="ADVISOR_ASYNC_ANSWER_SET" subjectId={answerSet.id} />
        <Stack direction="row" spacing={1}>
          {isAdmin && <Button onClick={handleDebugClick}>Debug</Button>}
          {isAdmin && <ViewAsyncAnswerRequestConfig request={answerSet.request} />}
        </Stack>

      </div>
    );
  };

  return (
    <>
      <Box sx={{ mt: 4, mb: 2 }}>
        {renderHeader()}
      </Box>
      {answerSet.request.expanded_question && (
        <div style={{ marginBottom: 20 }}>
          <Typography component="div" sx={{ fontWeight: 600 }}>We understood your question as:</Typography>
          <Typography component="div">{answerSet.request.expanded_question}</Typography>
        </div>
      )}
      <AdvisorAnswers
        answerSet={answerSet}
        laws={laws}
        decisions={decisions}
        sourceMap={sourceMap}
      />
      {laws?.length > 0 && (
        <Accordion className={classes.accordion} disableGutters elevation={0} square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography sx={{ fontWeight: 600 }}>Laws</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 0 }}>
            {renderLawSections()}
          </AccordionDetails>
        </Accordion>
      )}
      {decisions?.length > 0 && (
        <Accordion className={classes.accordion} disableGutters elevation={0} square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography sx={{ fontWeight: 600 }}>Court decisions</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 0 }}>
            {renderDecisions()}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default AdvisorAnswerSet;
