import React from 'react';

import { useMobile } from 'breakpoints';

import AppWrapperDesktop from './AppWrapperDesktop';
import AppWrapperMobile from './AppWrapperMobile';

function AppWrapper(props) {
  const isMobile = useMobile();

  if (isMobile) {
    return <AppWrapperMobile {...props} />;
  }

  return <AppWrapperDesktop {...props} />;
}

export default AppWrapper;
