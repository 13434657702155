const getQuestionsFromSheet = (sheet) => {
  const res = [];

  sheet.getColumn('B').eachCell((cell, rowNumber) => {
    if (rowNumber >= 3) {
      res.push(cell.value);
    }
  });

  return res;
};

export default getQuestionsFromSheet;
