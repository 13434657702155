/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  subjectIds: {},
};

export const slice = createSlice({
  name: 'submittedFeedbacks',
  initialState,
  reducers: {
    loadFeedbacksForSearchResultRequest: (state) => {
      state.isLoading = true;
    },
    loadFeedbacksForSearchResultSuccess: (state, { payload: { subjectIds } }) => {
      state.subjectIds = subjectIds;
      state.isLoading = false;
    },
    loadFeedbacksForAnswerSet: (state) => {
      state.isLoading = true;
    },
    loadFeedbacksForAnswerSetSuccess: (state, { payload: { subjectIds } }) => {
      state.subjectIds = subjectIds;
      state.isLoading = false;
    },
    setFeedback: (state, { payload: { feedback } }) => {
      state.subjectIds[feedback.subject_id] = feedback;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
