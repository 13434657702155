/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { format } from 'date-fns';
import {
  Typography, Link, IconButton,
} from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { getCourtLogo } from 'utils/publicImages';

import SubjectReactions from 'components/SubjectReactions';

import useStyles from './SearchResultsCardHeader.styles';

const SearchResultsCardHeader = ({
  searchResultItemId,
  courtId,
  caseNumber,
  title,
  date,
  link,
  withReactions = true,
  onTitleClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.courtLogo} style={{ backgroundImage: `url(${getCourtLogo(courtId)})` }} />
      <div>
        <div className={classes.caseNumberWrapper}>
          <Link href="#" underline="hover">
            <Typography className={classes.caseNumber} onClick={onTitleClick}>
              {caseNumber || 'Case number will be here'}
            </Typography>
          </Link>
          <IconButton
            className={classes.linkButton}
            component="a"
            href={link}
            target="_blank"
            size="small"
          >
            <OpenInNewIcon />
          </IconButton>
        </div>
        <Typography className={classes.title} sx={{ paddingRight: '4px' }}>{title}</Typography>
        <Typography className={classes.date}>
          {format(new Date(date), 'yyyy-MM-dd')}
        </Typography>
        {withReactions && (
          <SubjectReactions
            className={classes.reactions}
            subjectType="COURT_DECISION_SEARCH_RESPONSE_ITEM"
            subjectId={searchResultItemId}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResultsCardHeader;
