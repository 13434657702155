import { createUseStyles } from 'react-jss';

const FILTERS_WIDTH = 260;

const useStyles = createUseStyles({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  progressWrapper: {
    position: 'relative',
    zIndex: 2,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  header: {
    height: 48,
    display: 'flex',
  },
  actions: {
    marginLeft: 'auto',

    '& > *': {
      marginLeft: 8,
    },
  },
  appBarPlaceholder: {
    height: 64,
    flexShrink: 0,
  },
  appBar: {
    position: 'fixed',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
  },
  contentWrapper: {
    flexGrow: 1,
    display: 'flex',
    height: 'calc(100% - 64px)',
  },
  leftPanelWrapper: {
    flexShrink: 0,
    width: FILTERS_WIDTH,
  },
  filtersOpen: {},
  leftPanel: {
    transition: 'transform 225ms',
    background: 'white',
    height: '100%',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    transform: `translateX(-${FILTERS_WIDTH}px)`,
    width: FILTERS_WIDTH,
    overflow: 'hidden',

    '@media (max-width: 768px)': {
      display: 'none',
    },

    '$filtersOpen &': {
      transform: 'none',
    },
  },
  content: {
    padding: 16,
    overflow: 'auto',
    marginLeft: -FILTERS_WIDTH,
    width: '100%',
    transition: 'margin-left 225ms, width 225ms, padding-left 225ms',

    '$filtersOpen &': {
      marginLeft: -FILTERS_WIDTH,
      // width: `calc(100% - ${FILTERS_WIDTH}px)`,
      // paddingLeft: 20,
    },
  },
});

export default useStyles;
