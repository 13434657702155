import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';

import AvatarMenu from 'components/AvatarMenu';

import PricingTablePage from 'pages/PricingTablePage';

import useStyles from './SubscriptionCanceledPage.styles';

const SubscriptionCanceledPage = () => {
  const classes = useStyles();
  const { user } = useAuth0();

  if (user.org_id) {
    return (
      <div className={classes.root}>
        <AvatarMenu className={classes.avatarMenu} />
        <Typography>Your subscription is canceled</Typography>
      </div>
    );
  }

  return <PricingTablePage />;
};

export default SubscriptionCanceledPage;
