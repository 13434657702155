const isRequestBodyValid = (body) => {
  const lawPromptTemplate = body.law_rules.prompt_template;
  const courtPromptTemplate = body.court_decision_rules.prompt_template;

  return (
    courtPromptTemplate.includes('<question>') && courtPromptTemplate.includes('<court_decisions_prompt>')
    && lawPromptTemplate.includes('<question>') && lawPromptTemplate.includes('<laws_prompt>')
  );
};

export default isRequestBodyValid;
