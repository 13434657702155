import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';

import { mainSelectors } from 'redux/selectors';

import {
  AppBar, Box, Toolbar, Typography, Container, Button, LinearProgress,
} from '@mui/material';

import AvatarMenu from 'components/AvatarMenu';
import AppsMenu from 'components/AppsMenu';

import withAdminRequired from 'hocs/withAdminRequired';
import useStyles from './AdminPageWrapper.styles';

function AppWrapper({ children, title, actions }) {
  const classes = useStyles();
  const isLoading = useSelector(mainSelectors.isLoadingSelector);
  const { pathname } = useLocation();

  const pages = [
    ['Test bench', '/admin/test-bench', (url) => url === pathname],
    ['Searches', '/admin/searches', (url) => url === pathname],
    ['Answer sets', '/admin/answer-sets', (url) => url === pathname],
    ['Async Answers', '/admin/async-answers', (url) => url === pathname],
    ['Laws', '/admin/laws', (url) => url === pathname],
  ];

  return (
    <div>
      <div className={classes.progressWrapper}>
        {isLoading && <LinearProgress className={classes.progress} />}
      </div>
      <div className={classes.appBarPlaceholder} />
      <AppBar className={classes.appBar} position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <AppsMenu />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/search"
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                lineHeight: '20px',
              }}
            >
              <div>FINHOUND</div>
              <div style={{ fontSize: 16, letterSpacing: '.1rem' }}>Admin</div>
            </Typography>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              {pages.map(([pageTitle, url, getActive]) => (
                <Button
                  key={pageTitle}
                  sx={{
                    color: 'white',
                    display: 'block',
                    border: '1px solid',
                    borderColor: getActive(url) ? 'white' : 'transparent',
                    transition: 'borderColor 0.3',
                  }}
                  component={Link}
                  to={url}
                >
                  {pageTitle}
                </Button>
              ))}
              <AvatarMenu sx={{ ml: 'auto' }} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
        {(title || actions) && (
          <div className={classes.header}>
            <Typography
              gutterBottom
              variant="h6"
              sx={{ mb: 4 }}
            >
              {title}
            </Typography>
            <div className={classes.actions}>{actions}</div>
          </div>
        )}
        {children}
      </Container>
      <Helmet><title>FINHOUND: Admin</title></Helmet>
    </div>
  );
}

export default withAdminRequired(AppWrapper);
