import { combineReducers } from 'redux';

import main from './main/mainReducer';
import search from './search/searchReducer';
import decision from './decision/decisionReducer';
import facets from './facets/facetsReducer';
import submittedFeedbacks from './submittedFeedbacks/submittedFeedbacksReducer';
import user from './user/userReducer';

export default combineReducers({
  main,
  search,
  decision,
  facets,
  submittedFeedbacks,
  user,
});
