import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  paragraphText: {
    fontSize: 14,
  },
});

export default useStyles;
