import React, { useState } from 'react';

import { Dialog } from 'components/UIKit';

import { resetAnswerSettings } from 'utils/answersSettings';
import SettingsDialogContent from './SettingsDialogContent';

function SearchConfigDialog({ open, onClose }) {
  const [contentRenderKey, setContentRenderKey] = useState(0);

  const handleReset = () => {
    resetAnswerSettings();
    setContentRenderKey((prev) => prev + 1);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
    >
      <SettingsDialogContent key={contentRenderKey} onReset={handleReset} onClose={onClose} />
    </Dialog>
  );
}

export default SearchConfigDialog;
