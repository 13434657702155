import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Switch, TextField } from '@mui/material';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { paragraphTypes } from 'constants';

import { searchActions as actions } from 'redux/actions';

import { searchSelectors as selectors } from 'redux/selectors';

import useStyles from './SettingsDialogContent.styles';

const transformParagraphsToObj = (array) => {
  const res = {};

  array.forEach((val) => {
    res[val] = true;
  });

  return res;
};

const transformParagraphsToArray = (obj) => {
  const res = [];

  Object.entries(obj).forEach(([key, val]) => {
    if (val) {
      res.push(key);
    }
  });

  return res;
};

function SettingsDialogContent({ onClose, onReset }) {
  const classes = useStyles();
  const defaultSettings = useSelector(selectors.settingsSelector);

  const [selectedParagraphs, setSelectedParagraphs] = useState(
    transformParagraphsToObj(defaultSettings.paragraphTypes),
  );

  const [isMongoSearch, setIsMongoSearch] = useState(defaultSettings.isMongoSearch);
  const [mongoCutOff, setMongoCutOff] = useState(defaultSettings.mongoCutOff);
  const [pineconeCutOff, setPineconeCutOff] = useState(defaultSettings.pineconeCutOff);
  const [hideLowScoreResults, setHideLowScoreResults] = useState(defaultSettings.hideLowScoreResults);

  const dispatch = useDispatch();

  const handleParagraphsChange = (e, pType) => {
    setSelectedParagraphs((value) => ({ ...value, [pType]: e.target.checked }));
  };

  const handleSubmit = () => {
    const list = transformParagraphsToArray(selectedParagraphs);

    localStorage.setItem('searchSettings.paragraphTypes', JSON.stringify(list));
    localStorage.setItem('searchSettings.mongoCutOff', mongoCutOff);
    localStorage.setItem('searchSettings.pineconeCutOff', pineconeCutOff);
    localStorage.setItem('searchSettings.hideLowScoreResults', hideLowScoreResults ? 1 : 0);
    localStorage.setItem('searchSettings.isMongoVectorSearch', isMongoSearch ? '1' : '0');

    dispatch(actions.setSettings({
      paragraphTypes: list,
      mongoCutOff: Number(mongoCutOff),
      pineconeCutOff: Number(pineconeCutOff),
      hideLowScoreResults,
      isMongoSearch,
    }));
    onClose();
  };

  const handleReset = () => {
    localStorage.removeItem('searchSettings.paragraphTypes');
    localStorage.removeItem('searchSettings.mongoCutOff');
    localStorage.removeItem('searchSettings.pineconeCutOff');
    localStorage.removeItem('searchSettings.hideLowScoreResults');
    localStorage.removeItem('searchSettings.isMongoVectorSearch');

    dispatch(actions.resetSettings());

    onReset();
  };

  const handleScoringChange = (event) => {
    setIsMongoSearch(event.target.value === 'mongo');
  };

  return (
    <>
      <DialogTitle>Search settings</DialogTitle>
      <DialogContent className={classes.content}>
        <Box sx={{ mx: 3 }}>
          <FormControl sx={{ display: 'block', position: 'relative' }}>
            <FormLabel>Scoring with</FormLabel>
            <RadioGroup
              style={{ width: 150 }}
              value={isMongoSearch ? 'mongo' : 'pinecone'}
              onChange={handleScoringChange}
            >
              <FormControlLabel sx={{ mt: 1 }} value="mongo" control={<Radio />} label="Mongo" />
              <FormControlLabel sx={{ mt: 1 }} value="pinecone" control={<Radio />} label="Pinecone" />
            </RadioGroup>
            <FormLabel style={{ position: 'absolute', top: 0, left: 200 }}>Score cut off</FormLabel>
            <TextField
              style={{
                position: 'absolute', top: 40, left: 200, opacity: isMongoSearch ? 1 : 0.5,
              }}
              variant="standard"
              size="small"
              value={mongoCutOff}
              onChange={(e) => setMongoCutOff(e.target.value)}
            />
            <TextField
              style={{
                position: 'absolute', top: 88, left: 200, opacity: isMongoSearch ? 0.5 : 1,
              }}
              variant="standard"
              size="small"
              value={pineconeCutOff}
              onChange={(e) => setPineconeCutOff(e.target.value)}
            />
          </FormControl>
          <FormGroup sx={{ mt: 3 }}>
            <FormLabel>Hide low score results</FormLabel>
            <Switch checked={hideLowScoreResults} onChange={(e) => setHideLowScoreResults(e.target.checked)} />
          </FormGroup>
          <FormControl sx={{ mt: 3 }}>
            <FormLabel component="legend">Search by</FormLabel>
            <FormGroup>
              {paragraphTypes.map((pType) => (
                <FormControlLabel
                  key={pType}
                  control={(
                    <Checkbox
                      checked={selectedParagraphs[pType] || false}
                      name={pType}
                      onChange={(e) => handleParagraphsChange(e, pType)}
                    />
                  )}
                  label={pType}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset}>Reset</Button>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" disabled={!mongoCutOff || !pineconeCutOff} onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </>
  );
}

export default SettingsDialogContent;
