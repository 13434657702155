const PARAGRAPHS = ['Title', 'Summary', 'SummaryDE', 'Thesis', 'Object', 'Tenor', 'Facts', 'Reasons'];
const CHAPTERS = ['SECTION', 'GUIDELINE', 'INTERPRETATION', 'EXEMPTION', 'ATTACHMENT', 'SUMMARY', 'SUMMARY_DE'];

const getParagraphs = (row, startColumnLetter, paragraphs) => {
  // eslint-disable-next-line no-underscore-dangle
  const startColumnIndex = row.getCell(startColumnLetter)._column._number;

  // eslint-disable-next-line max-len
  const res = [];

  paragraphs.forEach((p, index) => {
    const { value } = row.getCell(startColumnIndex + index);

    if (value) {
      res.push(p);
    }
  });

  return res;
};

const getConfigsFromSheet = (sheet) => {
  const res = [];

  sheet.eachRow((row, i) => {
    if (i >= 4) {
      if (!row.getCell('A').value) {
        // eslint-disable-next-line no-param-reassign
        row.getCell('A').value = i - 3;
      }

      const config = {
        id: row.getCell('A').value,
        input_expansion_on: Boolean(row.getCell('B').value),
        input_expansion_prompt_template: row.getCell('C').value,
        court_decision_rules: {
          engine: row.getCell('D').value,
          model: row.getCell('E').value,
          prompt_template: row.getCell('F').value,
          cut_off_score: row.getCell('G').value,
          top_results_amount: row.getCell('H').value,
          evidence_classification_on: Boolean(row.getCell('I').value),
          evidence_classification_prompt: row.getCell('J').value,
          evidence_cut_off_score: row.getCell('K').value,
          paragraph_types: getParagraphs(row, 'L', PARAGRAPHS),
          prompt_paragraph_types: getParagraphs(row, 'T', PARAGRAPHS),
        },
        law_rules: {
          engine: row.getCell('AB').value,
          model: row.getCell('AC').value,
          prompt_template: row.getCell('AD').value,
          cut_off_score: row.getCell('AE').value,
          top_results_amount: row.getCell('AF').value,
          evidence_classification_on: Boolean(row.getCell('AG').value),
          evidence_classification_prompt: row.getCell('AH').value,
          evidence_cut_off_score: row.getCell('AI').value,
          law_chapter_types: getParagraphs(row, 'AJ', CHAPTERS),
          include_interpretations: false,
          include_guidelines: false,
          include_exemptions: false,
        },
        summary_prompt_model: row.getCell('AQ').value,
        prompt_template: row.getCell('AR').value,
      };

      res.push(config);
    }
  });

  return res;
};

export default getConfigsFromSheet;
