import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Helmet from 'react-helmet';

import { mainSelectors } from 'redux/selectors';

import {
  AppBar, Box, Toolbar, Typography, LinearProgress,
} from '@mui/material';

import AvatarMenu from 'components/AvatarMenu';
import AppsMenu from 'components/AppsMenu';

import useStyles from './AppWrapperDesktop.styles';

function AppWrapperDesktop({
  children, headerContent, isFiltersOpen, filters, contentClassName, title, actions,
}) {
  const classes = useStyles();
  const isLoading = useSelector(mainSelectors.isLoadingSelector);

  return (
    <div className={classes.root}>
      <div className={classes.progressWrapper}>
        {isLoading && <LinearProgress className={classes.progress} />}
      </div>
      <div className={classes.appBarPlaceholder} />
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <AppsMenu />
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              lineHeight: '20px',
            }}
          >
            <div>FINHOUND</div>
            {title && <div style={{ fontSize: '16px', letterSpacing: '.1rem' }}>{title}</div>}
          </Typography>
          {actions}
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            {headerContent}
            <Box sx={{ ml: 'auto', pl: 4 }}>
              <AvatarMenu />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div className={cx(classes.contentWrapper, isFiltersOpen && classes.filtersOpen)}>
        <div className={classes.leftPanelWrapper}>
          <div
            className={classes.leftPanel}
          >
            {filters}
          </div>
        </div>
        <div className={cx(classes.content, contentClassName)}>
          {children}
        </div>
      </div>
      <Helmet><title>{title ? `FINHOUND: ${title}` : 'FINHOUND'}</title></Helmet>
    </div>
  );
}

export default AppWrapperDesktop;
