import React from 'react';

import { useSelector } from 'react-redux';

import { userSelectors } from 'redux/selectors';

import PricingTablePage from 'pages/PricingTablePage';
import SubscriptionPausedPage from 'pages/SubscriptionPausedPage';
import SubscriptionCanceledPage from 'pages/SubscriptionCanceledPage';

const withSubscriptionRequired = (WrappedComponent) => (props) => {
  const user = useSelector(userSelectors.rootSelector);

  if (user.hasActiveSubscription) {
    return <WrappedComponent {...props} />;
  }

  if (user.newCustomer) {
    return <PricingTablePage trialAvailable />;
  }

  if (user.hasCanceledSubscription) {
    return <SubscriptionCanceledPage />;
  }

  return <SubscriptionPausedPage />;
};

export default withSubscriptionRequired;
