import React, { useState } from 'react';

import {
  Switch, FormControlLabel,
  DialogTitle, DialogContent, DialogContentText,
  Accordion, AccordionSummary, Typography, AccordionDetails,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Dialog } from 'components/UIKit';

import SearchResultsCard from 'components/SearchResultsCard/SearchResultsCard';
import useAdminSearchResult from './hooks/useAdminSearchResult';

import useStyles from './AdminSearchResultDialog.styles';

const AdminSearchResultDialog = ({ data, onClose }) => {
  const classes = useStyles();

  const [onlyWithFeedbacks, setOnlyWithFeedbacks] = useState(true);

  const {
    searchResult,
    loading,
  } = useAdminSearchResult({ searchResultId: data.id });

  const renderFeedbacks = (feedbacks, feedbacksStr, accordionClass) => {
    if (feedbacks.length === 0) {
      return null;
    }

    return (
      <Accordion className={accordionClass}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.accordionTitle}>
            {`Feedbacks (${feedbacksStr})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {feedbacks.map((feedback) => (
            <div key={feedback.creator_id} className={classes.feedback}>
              <Typography>
                <b>User email:</b>
                {' '}
                {feedback.creator_email || 'None'}
              </Typography>
              <Typography>
                <b>Rating:</b>
                {' '}
                {feedback.rating}
              </Typography>
              <Typography>
                <b>Comment:</b>
                {' '}
                {feedback.comment || 'None'}
              </Typography>
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <LinearProgress />;
    }

    const getItems = () => {
      if (!onlyWithFeedbacks) {
        return searchResult.items;
      }

      return searchResult.items.filter(({ feedbacks }) => feedbacks.length > 0);
    };

    const items = getItems();

    return (
      <>
        <DialogTitle sx={{ pr: 6 }}>{searchResult.search_request.search_term}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            User email:
            {' '}
            {searchResult.creator_email || 'none'}
          </DialogContentText>
          <div style={{ marginTop: 8 }}>
            {renderFeedbacks(searchResult.feedbacks, searchResult.feedbacksStr)}
          </div>
          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 16,
          }}
          >
            <DialogContentText>
              Items
            </DialogContentText>
            <FormControlLabel
              control={<Switch defaultChecked onChange={(e) => setOnlyWithFeedbacks(e.target.checked)} />}
              label="Only with feedbacks"
            />
          </div>
          <div style={{ marginTop: 16 }}>
            {items.length === 0 && <Typography>No results</Typography>}
            {items.map((item) => (
              <SearchResultsCard
                key={item.id}
                searchResult={item}
                headerProps={{ withReactions: false }}
                footer={
                  renderFeedbacks(
                    item.feedbacks,
                    item.feedbacksStr,
                    classes.searchResultFeedbackAccordion,
                  )
                }
              />
            ))}
          </div>
        </DialogContent>
      </>
    );
  };

  return (
    <Dialog open fullWidth maxWidth="md" classes={{ paper: classes.dialogPaper }} withCloseButton onClose={onClose}>
      {renderContent()}
    </Dialog>
  );
};

export default AdminSearchResultDialog;
