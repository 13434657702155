import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: [0, 8],
    whiteSpace: 'normal',
  },
  logo: {
    width: 24,
    height: 24,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginRight: 4,
    flexShrink: 0,
  },
});

export default useStyles;
