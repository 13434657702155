import {
  all, put, takeLatest,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';

import { actions } from 'redux/facets/facetsReducer';
import { searchActions } from 'redux/actions';

import request from '../utils/sagas/request';

export function* getFacetsFlow({ payload: { selectAll } }) {
  try {
    const { data } = yield request({
      method: 'get',
      url: '/finhound-service/facets',
    });

    const courts = data.items.find((item) => item.facet_type === 'COURTS')
      .items.sort((a, b) => a.name.localeCompare(b.name));

    yield put(actions.getFacetsSuccess({ courts }));

    if (selectAll) {
      yield put(searchActions.setCourtIds({ value: courts.map(({ id }) => id) }));
    }
  } catch (e) {
    NM.error('Something went wrong');
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getFacetsRequest.type, getFacetsFlow),
  ]);
}
