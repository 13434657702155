import request from 'api/request';

import pLimit from 'p-limit';

async function createAnswer(body) {
  try {
    const { data } = await request({
      method: 'post',
      url: '/finhound-service/answers',
      data: body,
    });
    return data;
  } catch (error) {
    return null;
  }
}

function timeout(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function createAsyncAnswer(body, onSuccess, onFailure) {
  try {
    let { data: answer } = await request({
      method: 'post',
      url: '/finhound-service/async-answers',
      data: body,
    });

    while (answer.status === 'IN_PROGRESS') {
      // eslint-disable-next-line no-await-in-loop
      await timeout(1000);
      // eslint-disable-next-line no-await-in-loop
      const { data } = await request({
        method: 'get',
        url: `/finhound-service/async-answers/${answer.id}`,
      });
      answer = data;
    }

    if (['COMPLETE', 'PARTIAL_SUCCESS'].includes(answer.status)) {
      await request({
        method: 'post',
        url: `/finhound-service/async-answers/${answer.id}/_generate_summary`,
        data: {},
      });

      // eslint-disable-next-line no-constant-condition
      while (true) {
        // eslint-disable-next-line no-await-in-loop
        await timeout(1000);
        // eslint-disable-next-line no-await-in-loop
        const { data } = await request({
          method: 'get',
          url: `/finhound-service/async-answers/${answer.id}`,
        });

        if (data.summary) {
          answer = data;
          break;
        }
      }
    }

    onSuccess();

    return answer;
  } catch (error) {
    onFailure();
    return null;
  }
}

export const makeAnswerSetTestBenchRequests = (requests, limit, isAsync, onSuccess, onFailure) => {
  const limitInst = pLimit(limit);

  const requestMethod = isAsync ? createAsyncAnswer : createAnswer;

  const createAnswersForOneQuestion = (arr) => Promise.all(
    arr.map((item) => limitInst(() => requestMethod(item, onSuccess, onFailure))),
  );

  return Promise.all(requests.map((group) => createAnswersForOneQuestion(group)));
};
