import React from 'react';

import {
  Paper, Typography, Stack, Divider, Link,
} from '@mui/material';

import { useIsAdmin } from 'hooks';

import Markdown from './Markdown';

import AdviserAnswerActions from './AdvisorAnswerActions';

const AdvisorAnswers = ({
  answerSet, laws, decisions, sourceMap,
}) => {
  const isAdmin = useIsAdmin();
  const items = [];

  if (answerSet.law_based_answers) {
    answerSet.law_based_answers.forEach((answer, index) => {
      const law = laws?.[index];

      if (answer.answer) {
        items.push({
          title: 'Based on law',
          link: law && ({
            title: law.title,
            id: law.id,
          }),
          answer,
          type: 'ADVISOR_LAW_BASED_ASYNC_ANSWER',
        });
      }
    });
  }

  if (answerSet.court_decision_based_answers) {
    answerSet.court_decision_based_answers.forEach((answer, index) => {
      if (answer.answer) {
        const decision = decisions?.[index];

        items.push({
          title: 'Based on court decision',
          link: decision && ({
            title: decision.case_number,
            id: decision.id,
          }),
          answer,
          type: 'ADVISOR_COURT_DECISION_BASED_ASYNC_ANSWER',
        });
      }
    });
  }

  if (items.length === 0) {
    return null;
  }

  const handleItemLinkClick = (id) => {
    const matchingLeftPanelElement = document.getElementById(id);

    if (matchingLeftPanelElement) {
      matchingLeftPanelElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {answerSet.summary && (
        <Paper sx={{ p: 2, whiteSpace: 'pre-wrap', position: 'relative' }}>
          <Stack direction="row" sx={{ justifyContent: 'flex-end', mb: 2 }}>
            <AdviserAnswerActions
              prompt={answerSet.summary_prompt}
              answerType={null}
            />
          </Stack>
          <Markdown text={answerSet.summary} sourceMap={sourceMap} />
        </Paper>
      )}
      <Paper sx={{
        mt: 4, p: 2, whiteSpace: 'pre-wrap', position: 'relative',
      }}
      >
        <Stack
          divider={<Divider flexItem />}
          spacing={3}
        >
          {items.map((item) => (
            <div key={item.answer.id}>
              <Stack direction="row" sx={{ justifyContent: 'space-between', mb: 2 }}>
                <Typography sx={{ fontWeight: 600 }}>
                  {item.title}
                  {' '}
                  {item.link && (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link href="#" onClick={() => handleItemLinkClick(item.link.id)}>
                      (
                        {item.link.title}
                      )
                    </Link>
                  )}
                </Typography>
                <AdviserAnswerActions
                  answerId={item.answer.id}
                  prompt={item.answer.prompt}
                  answerType={item.type}
                />
              </Stack>
              {item.answer.answer}
              {isAdmin && item.answer.exceeded_paragraphs_count > 0 && (
                <Typography sx={{ mt: 2, color: 'warning.main' }}>
                  Exceeded paragraphs:
                  {' '}
                  {item.answer.exceeded_paragraphs_count}
                </Typography>
              )}
            </div>
          ))}
        </Stack>
      </Paper>
    </>
  );
};

export default AdvisorAnswers;
