import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Typography, Divider, LinearProgress,
} from '@mui/material';

import { searchActions } from 'redux/actions';

import { searchSelectors, facetsSelectors } from 'redux/selectors';

import { getCourtLogo } from 'utils/publicImages';
import CourtsFilterMenuItem from './CourtsFilterMenuItem';
import useStyles from './Filters.styles';

function Filters() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const courtIdsSet = useSelector(searchSelectors.courtIdsSetSelector);
  const courtList = useSelector(facetsSelectors.courtsSelector);

  const isAllSelected = courtList.length === courtIdsSet.size;
  const indeterminate = courtIdsSet.size > 0 && !isAllSelected;

  const handleCourtChange = (id, value) => {
    dispatch(searchActions.toggleCourt({ id, value }));
  };

  const handleAllSelect = (value) => {
    dispatch(searchActions.setCourtIds({
      value: value ? courtList.map(({ id }) => id) : [],
    }));
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom sx={{ px: 2 }}>Courts</Typography>
      {courtList.length === 0 ? (
        <LinearProgress />
      ) : (
        <>
          <CourtsFilterMenuItem
            label="Select all"
            checked={isAllSelected}
            indeterminate={indeterminate}
            onChange={handleAllSelect}
          />
          <Divider />

          {courtList.map((court) => (
            <CourtsFilterMenuItem
              key={court.id}
              label={`${court.name} (${court.hits})`}
              logo={getCourtLogo(court.id)}
              checked={courtIdsSet.has(court.id)}
              onChange={(value) => handleCourtChange(court.id, value)}
            />
          ))}
        </>
      )}
    </div>
  );
}

export default Filters;
