import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  paragraph: {
    borderBottom: '1px dashed grey',
    paddingBottom: 4,

    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
});

export default useStyles;
