/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography, Paper,
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { decisionActions as actions } from 'redux/actions';

import SummaryAccordion from 'components/SummaryAccordion';
import SearchResultCardHeader from './SearchResultsCardHeader';

import useStyles from './SearchResultsCard.styles';

const SearchResultsCard = ({
  headerProps,
  searchResult: {
    id: searchResultItemId,
    case_number: caseNumber,
    paragraphs,
    court_ids: courtIds,
    title,
    decision_date: date,
    link,
    summary,
    summary_de: summaryDE,
    court_decision_id: courtDecisionId,
    lowScore,
  },
  footer,
}) => {
  const [summaryLang, setSummaryLang] = useState('de');
  const courtId = courtIds[0];

  const classes = useStyles();
  const dispatch = useDispatch();

  const getSelectedParagraphIds = () => {
    const res = {};

    paragraphs.forEach((item) => {
      res[item.id] = true;
    });

    return res;
  };

  const handleTitleClick = (e) => {
    e.preventDefault();
    dispatch(actions.getDecisionRequest({
      id: courtDecisionId,
      selectedParagraphIds: getSelectedParagraphIds(),
      summaryLang,
    }));
  };

  const handleSummaryLanguageChange = (newValue) => {
    setSummaryLang(newValue);
  };

  return (
    <Paper className={classes.root} style={{ opacity: lowScore ? 0.5 : 1 }}>
      <SearchResultCardHeader
        searchResultItemId={searchResultItemId}
        courtId={courtId}
        caseNumber={caseNumber}
        title={title}
        date={date}
        link={link}
        onTitleClick={handleTitleClick}
        {...headerProps}
      />
      <SummaryAccordion
        summaryEn={summary}
        summaryDe={summaryDE}
        onChange={handleSummaryLanguageChange}
      />
      {paragraphs.length > 0 && (
        <Accordion className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.accordionTitle}>Relevant</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {paragraphs.map((paragraph) => (
              <div
                key={paragraph.id}
                className={classes.paragraph}
                style={{ opacity: !lowScore && paragraph.lowScore ? 0.5 : 1 }}
              >
                <Typography className={classes.paragraphChapterType}>{paragraph.chapter_type}</Typography>
                <Typography className={classes.paragraphText}>
                  {paragraph.text}
                </Typography>
                <Typography className={classes.date}>
                  {paragraph.score}
                </Typography>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
      {footer}
    </Paper>
  );
};

export default SearchResultsCard;
