export const paragraphTypes = [
  'Title',
  'Summary',
  'SummaryDE',
  'Thesis',
  'Object',
  'Tenor',
  'Facts',
  'Reasons',
];
