/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courts: null,
};

export const slice = createSlice({
  name: 'facets',
  initialState,
  reducers: {
    getFacetsRequest: () => {},
    getFacetsSuccess: (state, { payload: { courts } }) => {
      state.courts = courts;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
