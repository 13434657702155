import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  searchBar: {
    flexGrow: 1,
    marginTop: 16,
    marginBottom: 20,
  },
  searchBarTextField: {
    backgroundColor: 'white',
    borderRadius: 4,

    '& .MuiInputBase-root': {
      alignItems: 'flex-start',

      '& .MuiInputAdornment-root': {
        marginTop: 18,
      },
    },
  },
});

export default useStyles;
