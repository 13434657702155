import axios from 'axios';

const makeRequest = async (body, apiKey) => {
  const res = await axios({
    method: 'POST',
    url: 'https://api.openai.com/v1/completions',
    data: body,
    headers: {
      Authorization: `Bearer ${apiKey}`,
    },
  });

  return res.data.choices.map((choice) => Math.exp(choice.logprobs.top_logprobs[0].yes) * 100);
};

const makeRequests = async (requests, apiKey) => {
  const res = await Promise.all(requests.map((request) => makeRequest(request, apiKey)));

  return res;
};

export default makeRequests;
