import { NotificationManager as NM } from 'react-notifications';

const isConfigsValid = (configs) => {
  for (let i = 0; i < configs.length; i += 1) {
    const config = configs[i];

    if (
      !config.court_decision_rules.prompt_template.includes('<question>')
      || !config.court_decision_rules.prompt_template.includes('<court_decisions_prompt>')
    ) {
      NM.error(`configs[${i}].court_decision_rules.prompt_template is not valid`);

      return false;
    }

    if (
      !config.law_rules.prompt_template.includes('<question>')
      || !config.law_rules.prompt_template.includes('<laws_prompt>')
    ) {
      NM.error(`configs[${i}].law_rules.prompt_template is not valid`);

      return false;
    }
  }

  return true;
};

export default isConfigsValid;
