import { createUseStyles } from 'react-jss';
import { media } from 'breakpoints';

const useStyles = createUseStyles({
  pageContent: {
    padding: [16, 280],

    '@media (max-width: 1024px)': {
      padding: [16, 16, 16, 280],
    },

    '@media (max-width: 768px)': {
      padding: 16,
    },
  },
  searchBar: {
    marginLeft: 10,
    marginRight: 16,
    flexGrow: 1,
    maxWidth: 'calc(100% - 215px)',

    [media.mobile]: {
      maxWidth: 'none',
    },
  },
});

export default useStyles;
