import _cloneDeep from 'lodash/cloneDeep';

const filterSteps = {
  NONE: 'none',
  VECTOR_SEARCH: 'vector-search',
  VECTOR_SEARCH_CUTOFF: 'vector-search-cutoff',
  EVIDENCE_CLASSIFICATION_CUTOFF: 'evidence-classification-cutoff',
};

const getFilteredStep = (lawsParam, filterStep) => {
  const laws = _cloneDeep(lawsParam);

  const filterParagraphs = (paragraphs) => paragraphs.filter((paragraph) => {
    switch (filterStep) {
      case filterSteps.VECTOR_SEARCH:
        return paragraph.debugInfo.db_score;
      case filterSteps.VECTOR_SEARCH_CUTOFF:
        return paragraph.debugInfo.approved1;
      case filterSteps.EVIDENCE_CLASSIFICATION_CUTOFF:
        return paragraph.debugInfo.approved2;
      default:
        return true;
    }
  });

  laws.forEach((law) => {
    law.sections.forEach((section) => {
      section.additions.forEach((addition) => {
        // eslint-disable-next-line no-param-reassign
        addition.paragraphs = filterParagraphs(addition.paragraphs);
      });

      // eslint-disable-next-line no-param-reassign
      section.additions = section.additions.filter(({ paragraphs }) => paragraphs.length > 0);

      // eslint-disable-next-line no-param-reassign
      section.paragraphs = filterParagraphs(section.paragraphs);
    });

    // eslint-disable-next-line no-param-reassign
    law.sections = law.sections.filter(({ additions, paragraphs }) => additions.length > 0 || paragraphs.length > 0);

    law.attachments.forEach((attachment) => {
      // eslint-disable-next-line no-param-reassign
      attachment.paragraphs = filterParagraphs(attachment.paragraphs);
    });

    // eslint-disable-next-line no-param-reassign
    law.attachments = law.attachments.filter(({ paragraphs }) => paragraphs.length > 0);
  });

  return laws.filter(({ attachments, sections }) => attachments.length > 0 || sections.length > 0);
};

export default getFilteredStep;
