const rootSelector = (state) => state.search;

export const searchResultSelector = (state) => rootSelector(state).searchResult;

export const searchResultsIdSelector = (state) => searchResultSelector(state)?.id;

export const settingsSelector = (state) => rootSelector(state).settings;

export const paragraphTypesSelector = (state) => rootSelector(state).settings.paragraphTypes;

export const isMongoSearchSelector = (state) => rootSelector(state).settings.isMongoSearch;

export const courtIdsSelector = (state) => rootSelector(state).courtIds;

export const courtIdsSetSelector = (state) => new Set(courtIdsSelector(state));

export const isFiltersOpenSelector = (state) => rootSelector(state).isFiltersOpen;

export const isSavedSearchLoadingErrorSelector = (state) => rootSelector(state).isSavedSearchLoadingError;

export const searchTermSelector = (state) => rootSelector(state).searchTerm;

export const isInitializedSelector = (state) => rootSelector(state).isInitialized;

export const searchResultItemsSelector = (state) => {
  const { pineconeCutOff, hideLowScoreResults } = settingsSelector(state);
  const searchResult = searchResultSelector(state);
  const items = searchResult?.items;

  if (!items) {
    return null;
  }

  const cutOff = pineconeCutOff;

  const res = [];

  items.forEach((item) => {
    if (hideLowScoreResults) {
      const paragraphs = item.paragraphs.filter(({ score }) => score >= cutOff);

      if (paragraphs.length > 0) {
        res.push({
          ...item,
          paragraphs,
        });
      }
    } else {
      const paragraphs = item.paragraphs.map((paragraph) => ({
        ...paragraph,
        lowScore: paragraph.score && paragraph.score < cutOff,
      }));

      res.push({
        ...item,
        paragraphs,
        lowScore: paragraphs.length > 0 && paragraphs.every(({ lowScore }) => lowScore),
      });
    }
  });

  return res;
};
