import React from 'react';

import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';

import useStyles from './CourtsFilterMenuItem.styles';

function CourtsFilterMenuItem({
  checked, indeterminate, label, logo, onChange, ...otherProps
}) {
  const classes = useStyles();

  const handleClick = () => {
    if (indeterminate) {
      onChange(true);
    } else {
      onChange(!checked);
    }
  };

  return (
    <MenuItem className={classes.root} onClick={handleClick} {...otherProps}>
      <Checkbox
        size="small"
        checked={checked}
        indeterminate={indeterminate}
      />
      {logo && <div className={classes.logo} style={{ backgroundImage: `url(${logo})` }} />}
      {label}
    </MenuItem>
  );
}

export default CourtsFilterMenuItem;
