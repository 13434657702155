/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  decision: null,
  selectedParagraphIds: {},
  defaultSummaryLang: null,
  isLoading: false,
};

export const slice = createSlice({
  name: 'decision',
  initialState,
  reducers: {
    getDecisionRequest: (state) => {
      state.isLoading = true;
    },
    getDecisionSuccess: (state, { payload: { data, selectedParagraphIds, summaryLang } }) => {
      state.decision = data;
      state.selectedParagraphIds = selectedParagraphIds;
      state.defaultSummaryLang = summaryLang;
      state.isLoading = false;
    },
    setDecision: (state, { payload: { value } }) => {
      state.decision = value;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
