import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DialogContent,
  Typography,
  DialogTitle,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import { decisionSelectors as selectors } from 'redux/selectors';

import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import useStyles from './DecisionPreviewModalContent.styles';

const DecisionPreviewModalContent = () => {
  const classes = useStyles();

  const decision = useSelector(selectors.decisionSelector);
  const selectedParagraphIds = useSelector(selectors.selectedParagraphIdsSelector);
  const defaultSummaryLang = useSelector(selectors.defaultSummaryLangSelector);

  const [summaryLanguage, setSummaryLanguage] = useState(defaultSummaryLang);

  const renderParagraph = (paragraph, index) => (
    <Fragment key={paragraph.id}>
      {(index === 0 || paragraph.chapter_type !== decision.paragraphs[index - 1].chapter_type) && (
        <Typography variant="h6" gutterBottom>{paragraph.chapter_type}</Typography>
      )}
      <Typography
        sx={{
          background: (theme) => (selectedParagraphIds[paragraph.id]
            ? alpha(theme.palette.primary.light, 0.2)
            : undefined
          ),
        }}
        className={classes.paragraphText}
        variant="body1"
        gutterBottom
      >
        {paragraph.text}
      </Typography>
    </Fragment>
  );

  const selectedSummary = summaryLanguage === 'en' ? decision.summary : decision.summary_de;

  return (
    <>
      <DialogTitle>{decision.case_number}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>{decision.title}</Typography>
        <div className={classes.body}>
          <div className={classes.summaryTitleWrapper}>
            <Typography variant="h6">Summary</Typography>
            <LanguageSwitcher
              className={classes.languageSwitcher}
              value={summaryLanguage}
              onChange={(e, val) => setSummaryLanguage(val)}
            />
          </div>
          <Typography
            className={classes.paragraphText}
            variant="body1"
            gutterBottom
          >
            {selectedSummary || 'Summary will be here'}
          </Typography>
          {decision.paragraphs.map(renderParagraph)}
        </div>
      </DialogContent>
    </>
  );
};

export default DecisionPreviewModalContent;
