import { actions as decisionActions } from './decision/decisionReducer';
import { actions as facetsActions } from './facets/facetsReducer';
import { actions as mainActions } from './main/mainReducer';
import { actions as searchActions } from './search/searchReducer';
import { actions as submittedFeedbacksActions } from './submittedFeedbacks/submittedFeedbacksReducer';
import { actions as userActions } from './user/userReducer';

const { setIsGlobalLoading } = mainActions;

export {
  decisionActions,
  facetsActions,
  mainActions,
  searchActions,
  submittedFeedbacksActions,
  userActions,
  setIsGlobalLoading,
};
