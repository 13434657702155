import React, { useState } from 'react';

import {
  Button, Stepper, Step, StepLabel, StepContent,
} from '@mui/material';

import getQuestionsFromSheet from './utils/getQuestionsFromSheet';
import getConfigsFromSheet from './utils/getConfigsFromSheet';

import EvidenceClassificationTestBenchDialog from
  './EvidenceClassificationTestBenchDialog/EvidenceClassificationTestBenchDialog';

const ExcelJS = require('exceljs');

const TestBenchAnswerSetsTabContent = () => {
  const [requestsData, setRequestsData] = useState(null);

  const handleFilesInput = async (e) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const arrayBuffer = event.target.result;
      const buffer = new Uint8Array(arrayBuffer);

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);

      const configs = getConfigsFromSheet(workbook.getWorksheet(2));

      const firstRow = workbook.getWorksheet(1).getRow(1);

      workbook.getWorksheet(1).spliceColumns(6, 1000);

      configs.forEach((config, index) => {
        firstRow.getCell(6 + index).value = `Config ${config.id}`;
        firstRow.getCell(6 + index).font = { bold: true };
      });

      const questions = getQuestionsFromSheet(workbook.getWorksheet(1));

      setRequestsData({ questions, configs, workbook });
    };

    reader.readAsArrayBuffer(e.target.files[0]);

    e.target.value = '';
  };

  return (
    <>
      <Stepper orientation="vertical">
        <Step active>
          <StepLabel>Download a template</StepLabel>
          <StepContent>
            <Button
              variant="contained"
              component="a"
              href={`${process.env.PUBLIC_URL}/Test bench evidence classification.xlsx`}
            >
              Download
            </Button>
          </StepContent>
        </Step>
        <Step active>
          <StepLabel>Fill in the template</StepLabel>
        </Step>
        <Step active>
          <StepLabel>Upload the modified file</StepLabel>
          <StepContent>
            <Button
              variant="contained"
              component="label"
            >
              Upload
              <input
                type="file"
                accept=".xlsx"
                hidden
                onChange={handleFilesInput}
              />
            </Button>
          </StepContent>
        </Step>
      </Stepper>
      {requestsData && (
        <EvidenceClassificationTestBenchDialog
          requestsData={requestsData}
          onClose={() => setRequestsData(null)}
        />
      )}
    </>
  );
};

export default TestBenchAnswerSetsTabContent;
