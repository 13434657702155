const getBoolValue = (value) => (value ? 'v' : undefined);

const setConfigSheetValues = (sheet, config) => {
  const row = sheet.getRow(4);

  row.getCell('id').value = 1;
  row.getCell('c.input-expansion').value = getBoolValue(config.input_expansion_on);
  row.getCell('c.input-expansion-prompt-template').value = config.input_expansion_prompt_template;

  row.getCell('cdr.engine').value = config.courtDecisionRules.engine;
  row.getCell('cdr.model').value = config.courtDecisionRules.model;
  row.getCell('cdr.prompt').value = config.courtDecisionRules.tempPromptTemplate;
  row.getCell('cdr.cut-off').value = config.courtDecisionRules.mongoCutOffScore;
  row.getCell('cdr.top-results').value = config.courtDecisionRules.top_results_amount;
  row.getCell('cdr.evidence-classification').value = getBoolValue(config.courtDecisionRules.evidence_classification_on);
  row.getCell('cdr.evidence-classification-prompt').value = config.courtDecisionRules.evidence_classification_prompt;
  row.getCell('cdr.evidence-classification-cut-off').value = config.courtDecisionRules.evidence_cut_off_score;

  config.courtDecisionRules.paragraph_types.forEach((paragraph) => {
    row.getCell(`cdr.search-by.${paragraph}`).value = 'v';
  });

  config.courtDecisionRules.prompt_paragraph_types.forEach((paragraph) => {
    row.getCell(`cdr.prompt-paragraph-types.${paragraph}`).value = 'v';
  });

  row.getCell('lr.engine').value = config.lawRules.engine;
  row.getCell('lr.model').value = config.lawRules.model;
  row.getCell('lr.prompt').value = config.lawRules.tempPromptTemplate;
  row.getCell('lr.cut-off').value = config.lawRules.mongoCutOffScore;
  row.getCell('lr.top-results').value = config.lawRules.top_results_amount;
  row.getCell('lr.evidence-classification').value = getBoolValue(config.lawRules.evidence_classification_on);
  row.getCell('lr.evidence-classification-prompt').value = config.lawRules.evidence_classification_prompt;
  row.getCell('lr.evidence-classification-cut-off').value = config.lawRules.evidence_cut_off_score;

  config.lawRules.law_chapter_types.forEach((paragraph) => {
    row.getCell(`lr.search-by.${paragraph}`).value = 'v';
  });

  row.getCell('c.model').value = config.summary_prompt_model;
  row.getCell('c.prompt').value = config.prompt_template;
};

export default setConfigSheetValues;
