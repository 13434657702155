import React from 'react';

import { Box, FormGroup, Typography } from '@mui/material';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import {
  TextFieldController,
  RadioGroupController,
  CheckboxGroupController,
  SwitchController,
} from 'components/formControllers';

import ModelSelect from '../ModelSelect';

const CHAPTER_TYPES = ['SECTION', 'GUIDELINE', 'INTERPRETATION', 'EXEMPTION', 'ATTACHMENT', 'SUMMARY', 'SUMMARY_DE'];

function AnswersLawRulesForm({ control, watch }) {
  const isMongoSearch = watch('lawRules.engine') === 'MONGO';
  const isEvidenceClassificationEnabled = watch('lawRules.evidence_classification_on');

  return (
    <Box sx={{ mx: 3 }}>
      <ModelSelect control={control} name="lawRules.model" />
      <TextFieldController
        sx={{ mb: 3, mt: 3, display: 'block' }}
        fullWidth
        control={control}
        name="lawRules.tempPromptTemplate"
        multiline
        label="Prompt template"
        helperText="Placeholders '<question>' and '<laws_prompt>' must be present in the template"
        rules={{
          pattern: {
            value: /(?=.*<question>)(?=.*<laws_prompt>)/s,
          },
        }}
      />
      <FormControl sx={{ display: 'block', position: 'relative' }}>
        <FormLabel>Scoring with</FormLabel>
        <RadioGroupController
          style={{ width: 150 }}
          control={control}
          name="lawRules.engine"
        >
          <FormControlLabel disabled sx={{ mt: 1 }} value="MONGO" control={<Radio />} label="Mongo" />
          <FormControlLabel disabled sx={{ mt: 1 }} value="PINECONE" control={<Radio />} label="Pinecone" />
        </RadioGroupController>
        <FormLabel style={{ position: 'absolute', top: 0, left: 200 }}>Score cut off</FormLabel>
        <TextFieldController
          disabled
          style={{
            position: 'absolute', top: 40, left: 200, opacity: isMongoSearch ? 1 : 0.5,
          }}
          variant="standard"
          size="small"
          control={control}
          name="lawRules.mongoCutOffScore"
        />
        <TextFieldController
          style={{
            position: 'absolute', top: 88, left: 200, opacity: isMongoSearch ? 0.5 : 1,
          }}
          variant="standard"
          size="small"
          control={control}
          name="lawRules.pineconeCutOffScore"
        />
      </FormControl>
      <FormControl sx={{ mt: 3 }}>
        <FormLabel component="legend">Search by</FormLabel>
        <CheckboxGroupController
          control={control}
          name="lawRules.law_chapter_types"
          options={CHAPTER_TYPES.map((val) => ({ label: val, value: val }))}
        />
      </FormControl>
      <TextFieldController
        sx={{ mt: 3, display: 'block' }}
        label="Top results amount"
        variant="standard"
        size="small"
        control={control}
        name="lawRules.top_results_amount"
      />
      <Typography variant="h6" sx={{ mt: 5 }}>Evidence classification</Typography>
      <FormGroup>
        <SwitchController
          sx={{ mt: 1 }}
          control={control}
          name="lawRules.evidence_classification_on"
          label="Enabled"
        />
      </FormGroup>
      {isEvidenceClassificationEnabled && (
        <>
          <TextFieldController
            sx={{ mb: 3, mt: 3, display: 'block' }}
            fullWidth
            control={control}
            name="lawRules.evidence_classification_prompt"
            multiline
            label="Prompt template"
            helperText="Placeholders '<text>' and '<question>' must be present in the template"
            rules={{
              pattern: {
                value: /(?=.*<text>)(?=.*<question>)/s,
              },
            }}
          />
          <TextFieldController
            sx={{ mt: 3, display: 'block' }}
            label="Evidence cut off score "
            variant="standard"
            size="small"
            control={control}
            name="lawRules.evidence_cut_off_score"
          />
        </>
      )}
    </Box>
  );
}

export default AnswersLawRulesForm;
