import React, { useState } from 'react';

import { Tabs, Tab } from '@mui/material';
import { JsonView, defaultStyles } from 'react-json-view-lite';

import { useForm } from 'react-hook-form';

import { NotificationManager as NM } from 'react-notifications';

import { setAnswersSettings } from 'utils/answersSettings';

import AnswersInputExpansionRulesForm from './AnswersCommonRulesForm';
import AnswersCourtDecisionRulesForm from './AnswersCourtDecisionRulesForm';
import AnswersLawRulesForm from './AnswersLawRulesForm';

function AdvisorConfigForm({
  defaultValues, disabled, json, onSuccess,
}) {
  const [activeTab, setActiveTab] = useState('decisions');

  const { control, handleSubmit, watch } = useForm({
    values: defaultValues,
  });

  const validate = () => {
    NM.error('Validation failed');
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const onSubmit = (values) => {
    setAnswersSettings({
      ...values,
      courtDecisionRules: {
        ...values.courtDecisionRules,
        top_results_amount: Number(values.courtDecisionRules.top_results_amount),
        evidence_cut_off_score: Number(values.courtDecisionRules.evidence_cut_off_score),
      },
      lawRules: {
        ...values.lawRules,
        top_results_amount: Number(values.lawRules.top_results_amount),
        evidence_cut_off_score: Number(values.lawRules.evidence_cut_off_score),
      },
    });
    NM.success('Config updated');
    if (onSuccess) {
      onSuccess();
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'common':
        return (
          <AnswersInputExpansionRulesForm control={control} watch={watch} />
        );
      case 'decisions':
        return (
          <AnswersCourtDecisionRulesForm control={control} watch={watch} />
        );
      case 'laws':
        return (
          <AnswersLawRulesForm control={control} watch={watch} />
        );
      case 'json':
        return (
          <JsonView data={json} style={defaultStyles} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Tabs value={activeTab} sx={{ mx: 3, mb: 3 }} onChange={handleTabChange}>
        <Tab label="Court decisions" value="decisions" />
        <Tab label="Laws" value="laws" />
        <Tab label="Common" value="common" />
        {json && <Tab label="JSON" value="json" />}
      </Tabs>
      <form id="answers-config" onSubmit={handleSubmit(onSubmit, validate)}>
        <fieldset style={{ border: 'none' }} disabled={disabled ? 'disabled' : undefined}>
          {renderTabContent()}
        </fieldset>
      </form>
    </>
  );
}

export default AdvisorConfigForm;
