import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@mui/material';

import AvatarMenu from 'components/AvatarMenu';
import useHasBillingAccess from 'hooks/useHasSubscriptionAccess';

import useStyles from './SubscriptionPausedPage.styles';

const SubscriptionPausedPage = () => {
  const classes = useStyles();

  const hasBillingAccess = useHasBillingAccess();

  return (
    <div className={classes.root}>
      <AvatarMenu className={classes.avatarMenu} />
      <Typography>Your subscription is paused</Typography>
      {hasBillingAccess && (
        <Button component={Link} sx={{ mt: 3 }} to="/billing">Open billing</Button>
      )}
    </div>
  );
};

export default SubscriptionPausedPage;
