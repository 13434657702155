import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  answer: {
    marginTop: 32,
  },
  answerInfoButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  accordion: {
    marginTop: 32,
    background: 'transparent',

    '&:before': {
      display: 'none',
    },
  },
});

export default useStyles;
