/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Dialog } from 'components/UIKit';

import FeedbackDialogContent from './FeedbackDialogContent';

const FeedbackDialog = ({
  open,
  ...otherProps
}) => (
  <Dialog fullWidth maxWidth="xs" open={open}>
    <FeedbackDialogContent {...otherProps} />
  </Dialog>
);

export default FeedbackDialog;
