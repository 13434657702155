import React from 'react';

import {
  Box, Typography, FormGroup,
} from '@mui/material';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import {
  TextFieldController, RadioGroupController, CheckboxGroupController, SwitchController,
} from 'components/formControllers';

import * as constants from 'constants';

import ModelSelect from '../ModelSelect';

function AnswersCourtDecisionRulesForm({ control, watch }) {
  const isMongoSearch = watch('courtDecisionRules.engine') === 'MONGO';
  const isEvidenceClassificationEnabled = watch('courtDecisionRules.evidence_classification_on');

  return (
    <Box sx={{ mx: 3 }}>
      <ModelSelect control={control} name="courtDecisionRules.model" />
      <TextFieldController
        sx={{ mb: 3, mt: 3, display: 'block' }}
        fullWidth
        control={control}
        name="courtDecisionRules.tempPromptTemplate"
        multiline
        label="Prompt template"
        helperText="Placeholders '<question>' and '<court_decisions_prompt>' must be present in the template"
        rules={{
          pattern: {
            value: /(?=.*<question>)(?=.*<court_decisions_prompt>)/s,
          },
        }}
      />
      <FormControl sx={{ display: 'block', position: 'relative' }}>
        <FormLabel>Scoring with</FormLabel>
        <RadioGroupController
          style={{ width: 150 }}
          control={control}
          name="courtDecisionRules.engine"
        >
          <FormControlLabel disabled sx={{ mt: 1 }} value="MONGO" control={<Radio />} label="Mongo" />
          <FormControlLabel disabled sx={{ mt: 1 }} value="PINECONE" control={<Radio />} label="Pinecone" />
        </RadioGroupController>
        <FormLabel style={{ position: 'absolute', top: 0, left: 200 }}>Score cut off</FormLabel>
        <TextFieldController
          disabled
          style={{
            position: 'absolute', top: 40, left: 200, opacity: isMongoSearch ? 1 : 0.5,
          }}
          variant="standard"
          size="small"
          control={control}
          name="courtDecisionRules.mongoCutOffScore"
        />
        <TextFieldController
          style={{
            position: 'absolute', top: 88, left: 200, opacity: isMongoSearch ? 0.5 : 1,
          }}
          variant="standard"
          size="small"
          control={control}
          name="courtDecisionRules.pineconeCutOffScore"
        />
      </FormControl>
      <FormControl sx={{ mt: 3 }}>
        <FormLabel component="legend">Search by</FormLabel>
        <CheckboxGroupController
          control={control}
          name="courtDecisionRules.paragraph_types"
          options={constants.paragraphTypes.map((val) => ({ label: val, value: val }))}
        />
      </FormControl>
      <TextFieldController
        sx={{ mt: 3, display: 'block' }}
        label="Top results amount"
        variant="standard"
        size="small"
        fullWidth
        control={control}
        name="courtDecisionRules.top_results_amount"
      />
      <FormControl sx={{ mt: 3 }}>
        <FormLabel component="legend">Prompt paragraph types</FormLabel>
        <CheckboxGroupController
          control={control}
          name="courtDecisionRules.prompt_paragraph_types"
          options={constants.paragraphTypes.map((val) => ({ label: val, value: val }))}
        />
      </FormControl>
      <Typography variant="h6" sx={{ mt: 5 }}>Evidence classification</Typography>
      <FormGroup>
        <SwitchController
          sx={{ mt: 1 }}
          control={control}
          name="courtDecisionRules.evidence_classification_on"
          label="Enabled"
        />
      </FormGroup>
      {isEvidenceClassificationEnabled && (
        <>
          <TextFieldController
            sx={{ mb: 3, mt: 3, display: 'block' }}
            fullWidth
            control={control}
            name="courtDecisionRules.evidence_classification_prompt"
            multiline
            label="Prompt template"
            helperText="Placeholders '<text>' and '<question>' must be present in the template"
            rules={{
              pattern: {
                value: /(?=.*<text>)(?=.*<question>)/s,
              },
            }}
          />
          <TextFieldController
            sx={{ mt: 3, display: 'block' }}
            label="Evidence cut off score "
            variant="standard"
            size="small"
            control={control}
            name="courtDecisionRules.evidence_cut_off_score"
          />
        </>
      )}
    </Box>
  );
}

export default AnswersCourtDecisionRulesForm;
