import {
  all, put, takeLatest, select,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';

import { actions } from 'redux/search/searchReducer';
import { actions as submittedFeedbacksActions } from 'redux/submittedFeedbacks/submittedFeedbacksReducer';
import { actions as decisionActions } from 'redux/decision/decisionReducer';
import { setIsGlobalLoading } from 'redux/main/mainReducer';
import * as selectors from 'redux/search/searchSelectors';
import request from '../utils/sagas/request';

export function* initFlow({ payload: { searchResultId } }) {
  if (searchResultId) {
    try {
      const { data } = yield request({
        method: 'get',
        url: `/finhound-service/court-decision-searches/${searchResultId}`,
      });

      if (data.search_request) {
        yield put(actions.getSearchResultSuccess({ data }));
        yield put(submittedFeedbacksActions.loadFeedbacksForSearchResultRequest({ searchResult: data }));
      }
    } catch (e) {
      NM.error('Something went wrong');
    }
  }

  yield put(actions.initComplete());
}

let prevSearchRequest = null;

export function* searchFlow({ payload: { searchTerm } }) {
  const paragraphTypes = yield select(selectors.paragraphTypesSelector);
  const courtIds = yield select(selectors.courtIdsSelector);

  const searchBody = {
    search_term: searchTerm,
    paragraph_types: paragraphTypes,
    court_ids: courtIds.length === 0 ? undefined : courtIds,
    mongo_search: false,
  };

  const stringifiedSearchRequest = JSON.stringify(searchBody);

  if (prevSearchRequest === stringifiedSearchRequest) {
    return;
  }

  prevSearchRequest = stringifiedSearchRequest;

  yield put(setIsGlobalLoading(true));
  try {
    const { data } = yield request({
      method: 'post',
      url: '/finhound-service/court-decision-searches',
      data: searchBody,
    });

    yield put(actions.searchSuccess({ data }));
  } catch (e) {
    NM.error('Something went wrong');
    prevSearchRequest = null;
  }
  yield put(setIsGlobalLoading(false));
}

export function* searchByDecisionIdFlow({ payload: { id } }) {
  yield put(setIsGlobalLoading(true));
  try {
    const { data } = yield request({
      method: 'get',
      url: `/finhound-service/court-decisions/${id}`,
    });

    yield put(actions.searchByDecisionIdSuccess({ data }));
    yield put(decisionActions.setDecision({ value: data }));
  } catch (e) {
    NM.error('Something went wrong');
  }
  yield put(setIsGlobalLoading(false));
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.searchRequest.type, searchFlow),
    takeLatest(actions.init, initFlow),
    takeLatest(actions.searchByDecisionIdRequest, searchByDecisionIdFlow),
  ]);
}
