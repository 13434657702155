import { createUseStyles } from 'react-jss';
import { alpha } from '@mui/material/styles';

const useStyles = createUseStyles({
  root: {},
  textField: {
    backgroundColor: '#fefefe',
    borderRadius: '5px',

    '& fieldset': {
      border: 'none !important',
    },

    '&:hover': {
      backgroundColor: alpha('#fefefe', 0.85),
    },

    '& .MuiInputBase-root': {
      padding: '1px 9px',
    },
  },
});

export default useStyles;
