import useState from 'react-usestateref';
import { useDispatch } from 'react-redux';
import { NotificationManager as NM } from 'react-notifications';

import { setIsGlobalLoading } from 'redux/actions';

import request from 'api/request';
import { useDidMount } from 'hooks';

const { subWeeks, format } = require('date-fns');

const PER_PAGE = 10;

async function fetchSearchById(id) {
  try {
    const response = await request({
      method: 'get',
      url: `/finhound-service/court-decision-searches/${id}`,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error');
  }
}

async function fetchFeedbacks(subjectType, subjectIds) {
  try {
    const response = await request({
      method: 'post',
      url: '/finhound-service/feedbacks/_search',
      data: {
        subject_type: subjectType,
        subject_ids: subjectIds,
      },
    });
    return response.data.items;
  } catch (error) {
    throw new Error('Error');
  }
}

const getSearchItemIds = (searches) => {
  const res = [];

  searches.forEach(({ items }) => {
    items.forEach(({ id }) => {
      res.push(id);
    });
  });

  return res;
};

const getFeedbackBySubject = (feedbacks) => {
  const res = {};

  feedbacks.forEach((({ subject_id: subjectId, rating }) => {
    if (!res[subjectId]) {
      res[subjectId] = [rating, rating];
    } else if (rating < res[subjectId][0]) {
      res[subjectId][0] = rating;
    } else if (rating > res[subjectId][1]) {
      res[subjectId][1] = rating;
    }
  }));

  return res;
};

const getDateFrom = (period) => {
  switch (period) {
    case 'lastWeek':
      return format(subWeeks(new Date(), 1), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    default:
      return undefined;
  }
};

const useSearchList = () => {
  const dispatch = useDispatch();
  const [page, setPage, pageRef] = useState(0);
  const [items, setItems] = useState(null);
  const [email, setEmail] = useState('');
  const [withFeedbacks, setWithFeedbacks, withFeedbacksRef] = useState(false);
  const [total, setTotal] = useState(false);
  const [period, setPeriod, periodRef] = useState('lastWeek');

  const searchItems = async () => {
    dispatch(setIsGlobalLoading(true));

    try {
      const { data } = await request({
        method: 'post',
        url: '/finhound-service/court-decision-searches/_search',
        data: {
          creator_email: email ? email.trim() : undefined,
          offset: PER_PAGE * pageRef.current,
          limit: PER_PAGE,
          with_feedbacks: withFeedbacksRef.current,
          created_from: getDateFrom(periodRef.current),
        },
      });

      setTotal(data.total);

      const ids = data.items.map(({ id }) => id);

      const searches = await Promise.all(ids.map(fetchSearchById));

      const searchItemIds = getSearchItemIds(searches);

      const promise = fetchFeedbacks('COURT_DECISION_SEARCH_RESPONSE', ids);
      const promiseItems = fetchFeedbacks('COURT_DECISION_SEARCH_RESPONSE_ITEM', searchItemIds);

      const [resultFeedbacks, resultItemsFeedback] = await Promise.all([promise, promiseItems]);

      // inject results feedbacks

      const resultFeedbacksByResult = getFeedbackBySubject(resultFeedbacks);
      const resultItemsFeedbackByResultItem = getFeedbackBySubject(resultItemsFeedback);

      searches.forEach((search) => {
        // eslint-disable-next-line no-param-reassign
        search.resultFeedbacks = resultFeedbacksByResult[search.id];

        const mins = [];
        const maxs = [];

        search.items.forEach(({ id }) => {
          const feedbacks = resultItemsFeedbackByResultItem[id];

          if (feedbacks) {
            mins.push(feedbacks[0]);
            maxs.push(feedbacks[1]);
          }
        });

        if (mins.length > 0) {
          // eslint-disable-next-line no-param-reassign
          search.resultItemsFeedbacks = [Math.min(...mins), Math.max(...maxs)];
        }
      });

      setItems(searches);
    } catch (e) {
      NM.error('Something went wrong');
    }

    dispatch(setIsGlobalLoading(false));
  };

  const handlePageChange = (val) => {
    setPage(val);
    searchItems();
  };

  const handleSubmit = () => {
    setPage(0);
    searchItems();
  };

  useDidMount(() => {
    searchItems();
  });

  const handleWithFeedbackChange = (val) => {
    setWithFeedbacks(val);
    handleSubmit();
  };

  const handlePeriodChange = (val) => {
    setPeriod(val);
    handleSubmit();
  };

  return {
    items,
    page,
    totalItems: total,
    rowsPerPage: PER_PAGE,
    email,
    withFeedbacks,
    period,
    onWithFeedbacksChange: handleWithFeedbackChange,
    onPageChange: handlePageChange,
    onEmailChange: setEmail,
    onPeriodChange: handlePeriodChange,
    onSubmit: handleSubmit,
  };
};

export default useSearchList;
