import React from 'react';
import { Provider } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import Helmet from 'react-helmet';

import AppRouter from 'components/AppRouter';
import store from 'redux/store';

import 'react-notifications/lib/notifications.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import jss from 'jss';
import preset from 'jss-preset-default';

jss.setup(preset());

function App() {
  return (
    <>
      <Helmet><title>Finhound</title></Helmet>
      <Provider store={store}>
        <AppRouter />
      </Provider>
      <NotificationContainer />
    </>
  );
}

export default App;
