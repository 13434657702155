import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    color: '#000',
    borderRadius: 5,
    padding: 11,

    '&:not(:last-of-type)': {
      marginBottom: 16,
    },
  },
  header: {
    display: 'flex',
    paddingBottom: 8,
    position: 'relative',
  },
  courtLogo: {
    flexShrink: 0,
    width: 40,
    height: 45,
    marginRight: 10,
    marginTop: 4,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  caseNumber: {
    fontWeight: 700,
    borderRadius: 5,
    paddingRight: 120,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 8,
  },
  date: {
    marginTop: 4,
    fontSize: 12,
    color: '#70757a',
  },
  paragraph: {
    padding: '8px 0',

    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  paragraphChapterType: {
    fontSize: 14,
    fontWeight: 500,
  },
  paragraphText: {
    fontSize: 14,
  },
  accordion: {
    boxShadow: 'none',
  },
  accordionTitle: {
    fontWeight: 500,
  },
  languageSwitcher: {
    marginLeft: 8,
  },
});

export default useStyles;
