import React from 'react';
import cx from 'classnames';

import { InputAdornment, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';

import useStyles from './SearchBar.styles';

function SearchBar({
  className,
  value,
  label,
  placeholder = 'Search...',
  options = [],
  textFieldClassName,
  textFieldProps,
  onClear,
  onKeyDown,
  ...otherProps
}) {
  const classes = useStyles();

  const renderEndAdornment = () => {
    if (!value) {
      return null;
    }

    return (
      <InputAdornment position="end">
        <IconButton
          edge="end"
          onClick={onClear}
        >
          <CloseIcon />
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    <Autocomplete
      className={cx(classes.root, className)}
      value={value}
      freeSolo
      disableClearable
      options={options}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          className={textFieldClassName || classes.textField}
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: renderEndAdornment(),
          }}
          onKeyDown={onKeyDown}
          {...textFieldProps}
        />
      )}
      {...otherProps}
    />
  );
}

export default SearchBar;
