import { useState, useEffect } from 'react';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

async function fetchLawById(id) {
  try {
    const response = await request({
      method: 'get',
      url: `/finhound-service/laws/${id}`,
      cache: true,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error');
  }
}

const getLawHits = (law) => {
  const res = {};

  law.paragraphs.forEach((paragraph) => {
    res[paragraph.id] = paragraph;
  });

  return res;
};

const useLaws = (answerSet) => {
  const [lawsState, setLaws] = useState(null);

  const fetchLaws = async () => {
    try {
      const laws = await Promise.all(answerSet.law_based_answers.map((answer) => fetchLawById(answer.laws[0].id)));

      laws.forEach((law, index) => {
        const hits = getLawHits(answerSet.law_based_answers[index].laws[0]);

        law.sections.forEach((section) => {
          section.paragraphs.forEach((paragraph) => {
            const hit = hits[paragraph.id];

            if (hit) {
              // eslint-disable-next-line no-param-reassign
              paragraph.hit = hit;
              // eslint-disable-next-line no-param-reassign
              section.hit = true;
            }
          });

          section.additions.forEach((addition) => {
            addition.paragraphs.forEach((paragraph) => {
              const hit = hits[paragraph.id];

              if (hit) {
                // eslint-disable-next-line no-param-reassign
                paragraph.hit = hit;
                // eslint-disable-next-line no-param-reassign
                section.hit = true;
                // eslint-disable-next-line no-param-reassign
                addition.hit = true;
              }
            });
          });
        });

        law.attachments.forEach((attachment) => {
          attachment.paragraphs.forEach((paragraph) => {
            const hit = hits[paragraph.id];

            if (hit) {
              // eslint-disable-next-line no-param-reassign
              paragraph.hit = hit;
              // eslint-disable-next-line no-param-reassign
              attachment.hit = true;

              attachment.section_ids.forEach((id) => {
                // eslint-disable-next-line no-param-reassign
                law.sections.find((section) => section.id === id).hit = true;
              });
            }
          });
        });

        // eslint-disable-next-line no-param-reassign
        law.sections = law.sections.filter(({ hit }) => hit);

        law.sections.forEach((section) => {
          // eslint-disable-next-line no-param-reassign
          section.additions = section.additions.filter(({ hit }) => hit);
        });

        // eslint-disable-next-line no-param-reassign
        law.attachments = law.attachments.filter(({ hit }) => hit);
        // eslint-disable-next-line no-param-reassign
        law.law = {
          title: law.title,
          link: law.link,
          summary: {
            en: law.summary,
            de: law.summary_de,
          },
        };
      });

      setLaws(laws);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useEffect(() => {
    if (answerSet?.law_based_answers?.length > 0) {
      fetchLaws();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerSet?.id, answerSet?.law_based_answers?.length]);

  useEffect(() => {
    setLaws(null);
  }, [answerSet?.id]);

  return lawsState;
};

export default useLaws;
