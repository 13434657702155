import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import AvatarMenu from 'components/AvatarMenu';

import useStyles from './PricingTablePage.styles';

const PricingTablePage = ({ trialAvailable }) => {
  const classes = useStyles();

  const { user } = useAuth0();

  return (
    <div className={classes.root}>
      <AvatarMenu className={classes.avatarMenu} />
      <stripe-pricing-table
        pricing-table-id={trialAvailable ? 'prctbl_1OWBMbIlNVjh2MwTivmbDjYp' : 'prctbl_1OWXaDIlNVjh2MwTYpP4LBCK'}
        // eslint-disable-next-line max-len
        publishable-key="pk_test_51OMpAFIlNVjh2MwTvASzifwU8ItrkO1aEFqgf23aM5k6S7RLkgP7yfIynf6T0IrhGkbIi1zktPOUdyP9isCWOSoe001w0Ma0e3"
        customer-email={user.email}
      />
    </div>
  );
};

export default PricingTablePage;
