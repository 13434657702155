import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  reactions: {
    display: 'flex',
    justifyContent: 'center',
  },
  reactionButton: {
    '& svg': {
      width: 48,
      height: 48,
    },
  },
  message: {
    marginTop: 16,
  },
});

export default useStyles;
