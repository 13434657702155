import React from 'react';
import { useSelector } from 'react-redux';

import { searchSelectors as selectors } from 'redux/selectors';

import { Typography } from '@mui/material';

import SearchResultsCard from 'components/SearchResultsCard';
import ResultFeedback from 'components/ResultFeedback';

const SearchResults = () => {
  const searchResult = useSelector(selectors.searchResultSelector);
  const items = useSelector(selectors.searchResultItemsSelector);

  if (!items) {
    return null;
  }

  if (items.length === 0) {
    return (
      <>
        <ResultFeedback searchResultId={searchResult.id} />
        <Typography>No results</Typography>
      </>
    );
  }

  return (
    <div style={{ maxWidth: 768, margin: 'auto' }}>
      <ResultFeedback subjectType="COURT_DECISION_SEARCH_RESPONSE" subjectId={searchResult.id} />
      {items.map((item) => (
        <SearchResultsCard
          key={item.id || item.court_decision_id}
          searchResult={item}
        />
      ))}
    </div>
  );
};

export default SearchResults;
