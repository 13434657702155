import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DialogContent, LinearProgress,
} from '@mui/material';

import { Dialog } from 'components/UIKit';

import { decisionSelectors as selectors } from 'redux/selectors';

import { decisionActions } from 'redux/actions';
import DecisionPreviewModalContent from './DecisionPreviewModalContent';
import useStyles from './DecisionPreviewModal.styles';

const DecisionPreviewModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const decision = useSelector(selectors.decisionSelector);
  const isLoading = useSelector(selectors.isLoadingSelector);

  if (!isLoading && !decision) {
    return null;
  }

  const handleClose = () => {
    dispatch(decisionActions.reset());
  };

  const renderContent = () => {
    if (isLoading) {
      return <DialogContent><LinearProgress sx={{ mt: 4 }} /></DialogContent>;
    }

    return <DecisionPreviewModalContent />;
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
      withCloseButton
      onClose={handleClose}
    >
      {renderContent()}
    </Dialog>
  );
};

export default DecisionPreviewModal;
