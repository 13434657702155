import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  Autocomplete, TextField,
} from '@mui/material';

import { useDidMount } from 'hooks';

import request from 'api/request';

import { NotificationManager as NM } from 'react-notifications';

const sortFunc = (a, b) => {
  // eslint-disable-next-line no-param-reassign
  a = a.number;
  // eslint-disable-next-line no-param-reassign
  b = b.number;
  // eslint-disable-next-line no-param-reassign
  a = Number.isNaN(parseInt(a, 10)) ? a : parseInt(a, 10);
  // eslint-disable-next-line no-param-reassign
  b = Number.isNaN(parseInt(b, 10)) ? b : parseInt(b, 10);

  // Perform a custom comparison
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const LawsSelector = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const lawId = searchParams.get('law_id');

  const [laws, setLaws] = useState([]);

  const loadList = async () => {
    try {
      const { data } = await request({
        method: 'post',
        url: '/finhound-service/laws/_list',
        data: {
          offset: 0,
          limit: 100,
        },
      });

      setLaws(data.items.sort(sortFunc));
    } catch {
      NM.error('Something went wrong');
    }
  };

  useDidMount(() => {
    loadList();
  });

  const handleChange = (event, newValue) => {
    searchParams.set('law_id', newValue.id);
    setSearchParams(searchParams);
  };

  const selectedValue = laws.find((law) => law.id === lawId);

  return (
    <Autocomplete
      value={selectedValue || null}
      disablePortal
      options={laws || []}
      getOptionLabel={(item) => item.title}
      renderInput={(params) => <TextField {...params} label={laws.length === 0 ? 'Loading...' : 'Law'} />}
      onChange={handleChange}
    />
  );
};

export default LawsSelector;
