import React from 'react';
import { useSearchParams } from 'react-router-dom';

import AdminPageWrapper from 'components/AdminPageWrapper';

import LawsSelector from './LawsSelector';
import LawPreview from './LawPreview';

const LawsViewerPage = () => {
  const [searchParams] = useSearchParams();

  const lawId = searchParams.get('law_id');

  return (
    <AdminPageWrapper>
      <LawsSelector />
      {lawId && (
        <div style={{ marginTop: 16 }}>
          <LawPreview key={lawId} lawId={lawId} />
        </div>
      )}
    </AdminPageWrapper>
  );
};

export default LawsViewerPage;
