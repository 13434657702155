/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, TextField } from '@mui/material';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import { submittedFeedbacksActions as actions } from 'redux/actions';

import useStyles from './FeedbackDialogContent.styles';

const reactions = [
  SentimentVeryDissatisfiedIcon,
  SentimentDissatisfiedIcon,
  SentimentNeutralIcon,
  SentimentSatisfiedIcon,
  SentimentVerySatisfiedIcon,
];

const FeedbackDialog = ({
  defaultRating = null,
  defaultComment = '',
  editMode,
  description = 'How do you feel about the results of your question?',
  subjectType,
  subjectId,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [rating, setRating] = useState(defaultRating);

  const [message, setMessage] = useState(defaultComment);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const { data } = await request({
        method: 'post',
        url: '/finhound-service/feedbacks',
        data: {
          subject_id: subjectId,
          subject_type: subjectType,
          rating,
          comment: message,
        },
      });
      dispatch(actions.setFeedback({ feedback: data }));
      NM.success('Thank you for your valuable feedback!');
      onClose();
    } catch (e) {
      NM.error('Something went wrong');
    }
    setIsLoading(false);
  };

  return (
    <>
      <DialogTitle>
        Share feedback
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
        <div className={classes.reactions}>
          {reactions.map((Icon, index) => (
            <IconButton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={classes.reactionButton}
              color={rating === index + 1 ? 'primary' : undefined}
              onClick={() => setRating(index + 1)}
            >
              <Icon />
            </IconButton>
          ))}
        </div>
        <TextField
          defaultValue={message}
          className={classes.message}
          fullWidth
          label="Your comments"
          variant="outlined"
          multiline
          rows={4}
          inputProps={{ maxLength: 2000 }}
          onBlur={(e) => setMessage(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!rating || isLoading} onClick={handleSubmit} variant="contained">
          {editMode ? 'Update feedback' : 'Send feedback'}
        </Button>
      </DialogActions>
    </>
  );
};

export default FeedbackDialog;
