const getSourceMap = (laws, decisions) => {
  const res = {};

  decisions?.forEach((court, index) => {
    res[`C0${index + 1}`] = {
      type: 'COURT_DECISION',
      data: court,
    };
  });

  laws?.forEach((law, index) => {
    res[`L0${index + 1}`] = {
      type: 'LAW',
      data: law,
    };
  });

  return res;
};

export default getSourceMap;
