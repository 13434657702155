import useState from 'react-usestateref';
import { useDispatch } from 'react-redux';
import { NotificationManager as NM } from 'react-notifications';

import { setIsGlobalLoading } from 'redux/actions';

import request from 'api/request';
import { useDidMount } from 'hooks';

const { subWeeks, format } = require('date-fns');

const PER_PAGE = 10;

async function fetchFeedbacks(subjectType, subjectIds) {
  try {
    const response = await request({
      method: 'post',
      url: '/finhound-service/feedbacks/_search',
      data: {
        subject_type: subjectType,
        subject_ids: subjectIds,
      },
    });
    return response.data.items;
  } catch (error) {
    throw new Error('Error');
  }
}

const getCourtAnswerIds = (answerSets) => {
  const res = [];

  answerSets.forEach((answerSet) => {
    answerSet.court_decision_based_answers?.forEach(({ id }) => {
      res.push(id);
    });
  });

  return res;
};

const getLawAnswerIds = (answerSets) => {
  const res = [];

  answerSets.forEach((answerSet) => {
    answerSet.law_based_answers?.forEach(({ id }) => {
      res.push(id);
    });
  });

  return res;
};

const getFeedbackBySubject = (feedbacks) => {
  const res = {};

  feedbacks.forEach((({ subject_id: subjectId, rating }) => {
    if (!res[subjectId]) {
      res[subjectId] = [rating, rating];
    } else if (rating < res[subjectId][0]) {
      res[subjectId][0] = rating;
    } else if (rating > res[subjectId][1]) {
      res[subjectId][1] = rating;
    }
  }));

  return res;
};

const getDateFrom = (period) => {
  switch (period) {
    case 'lastWeek':
      return format(subWeeks(new Date(), 1), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    default:
      return undefined;
  }
};

const AnswerSetList = () => {
  const dispatch = useDispatch();
  const [page, setPage, pageRef] = useState(0);
  const [items, setItems] = useState(null);
  const [email, setEmail] = useState('');
  const [withFeedbacks, setWithFeedbacks, withFeedbacksRef] = useState(false);
  const [total, setTotal] = useState(false);
  const [period, setPeriod, periodRef] = useState('lastWeek');

  const searchItems = async () => {
    dispatch(setIsGlobalLoading(true));

    try {
      const { data } = await request({
        method: 'post',
        url: '/finhound-service/async-answers/_search',
        data: {
          creator_email: email ? email.trim() : undefined,
          offset: PER_PAGE * pageRef.current,
          limit: PER_PAGE,
          with_feedbacks: withFeedbacksRef.current,
          created_from: getDateFrom(periodRef.current),
        },
      });

      setTotal(data.total);

      const answerSets = data.items;

      const ids = answerSets.map(({ id }) => id);
      const courtAnswerIds = getCourtAnswerIds(data.items);
      const lawAnswerIds = getLawAnswerIds(data.items);

      const [answerFeedbacks, courtFeedbacks, lawFeedbacks] = await Promise.all([
        fetchFeedbacks('ADVISOR_ASYNC_ANSWER_SET', ids),
        fetchFeedbacks('ADVISOR_COURT_DECISION_BASED_ASYNC_ANSWER', courtAnswerIds),
        fetchFeedbacks('ADVISOR_LAW_BASED_ASYNC_ANSWER', lawAnswerIds),
      ]);

      // inject results feedbacks

      const answerFeedbacksBySubject = getFeedbackBySubject(answerFeedbacks);
      const courtFeedbacksBySubject = getFeedbackBySubject(courtFeedbacks);
      const lawFeedbacksBySubject = getFeedbackBySubject(lawFeedbacks);

      answerSets.forEach((answerSet) => {
        // eslint-disable-next-line no-param-reassign
        answerSet.feedbacks = answerFeedbacksBySubject[answerSet.id];

        if (answerSet.court_decision_based_answers) {
          // eslint-disable-next-line no-param-reassign
          answerSet.courtFeedbacks = answerSet.court_decision_based_answers
            .map(({ id }) => courtFeedbacksBySubject[id]);
        } else {
          // eslint-disable-next-line no-param-reassign
          answerSet.courtFeedbacks = [];
        }

        if (answerSet.law_based_answers) {
          // eslint-disable-next-line no-param-reassign
          answerSet.lawFeedbacks = answerSet.law_based_answers.map(({ id }) => lawFeedbacksBySubject[id]);
        } else {
          // eslint-disable-next-line no-param-reassign
          answerSet.lawFeedbacks = [];
        }
      });

      setItems(answerSets);
    } catch (e) {
      NM.error('Something went wrong');
    }

    dispatch(setIsGlobalLoading(false));
  };

  const handlePageChange = (val) => {
    setPage(val);
    searchItems();
  };

  const handleSubmit = () => {
    setPage(0);
    searchItems();
  };

  useDidMount(() => {
    searchItems();
  });

  const handleWithFeedbackChange = (val) => {
    setWithFeedbacks(val);
    handleSubmit();
  };

  const handlePeriodChange = (val) => {
    setPeriod(val);
    handleSubmit();
  };

  return {
    items,
    page,
    totalItems: total,
    rowsPerPage: PER_PAGE,
    email,
    withFeedbacks,
    period,
    onWithFeedbacksChange: handleWithFeedbackChange,
    onPageChange: handlePageChange,
    onEmailChange: setEmail,
    onPeriodChange: handlePeriodChange,
    onSubmit: handleSubmit,
  };
};

export default AnswerSetList;
