import React from 'react';
import useIsAdmin from '../hooks/useIsAdmin';

const withAdminRequired = (WrappedComponent) => (props) => {
  const isAdmin = useIsAdmin();

  if (!isAdmin) {
    return null;
  }

  return <WrappedComponent {...props} />;
};

export default withAdminRequired;
