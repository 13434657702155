import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import { userSelectors } from 'redux/selectors';

const useHasBillingAccess = () => {
  const user = useSelector(userSelectors.rootSelector);
  const { user: auth0User } = useAuth0();

  if (user.newCustomer) {
    return false;
  }

  if (!auth0User.org_id) {
    if (!auth0User.email.includes('@bordercon.de')) {
      return false;
    }

    return true;
  }

  return user.permissions.includes('access:billing');
};

export default useHasBillingAccess;
