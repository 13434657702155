import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  body: {
    marginTop: 16,
  },
  paragraphText: {
    marginBottom: 16,
  },
  summaryTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.35em',
  },
  languageSwitcher: {
    marginLeft: 8,
  },
});

export default useStyles;
