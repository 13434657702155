import React from 'react';

import {
  Paper, Accordion, AccordionSummary, AccordionDetails, Typography, LinearProgress, Divider, Stack, Box,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SearchResultsCard from 'components/SearchResultsCard';

import LawSectionCard from 'components/LawSectionCard';
import ResultFeedback from 'components/ResultFeedback';

import AdviserAnswerActions from './AdvisorAnswerActions';

import useAdviserAnswerCourtDecisions from './hooks/useAdvisorAnswerCourtDecisions';
import useAdviserAnswerLawSections from './hooks/useAdvisorAnswerLawSections';

import useStyles from './AdvisorAnswer.styles';

const AdvisorAnswer = ({ answerSet }) => {
  const classes = useStyles();

  const {
    items: decisions,
  } = useAdviserAnswerCourtDecisions(answerSet.court_decision_based_answer?.court_decisions || []);
  const { items: laws } = useAdviserAnswerLawSections(answerSet?.law_based_answer?.laws || []);

  const renderDecisions = () => {
    if (!decisions) {
      return <LinearProgress />;
    }

    return decisions.map((decision) => (
      <SearchResultsCard
        key={decision.id}
        headerProps={{ withReactions: false }}
        searchResult={decision}
        summaryDefaultExpanded
      />
    ));
  };

  const renderLawSections = () => {
    if (!laws) {
      return <LinearProgress />;
    }

    return laws.map((law, index) => (
      <LawSectionCard
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        {...law}
      />
    ));
  };

  return (
    <>
      <Box sx={{ mt: 4, mb: 2 }}>
        <ResultFeedback subjectType="ADVISOR_ANSWER_SET" subjectId={answerSet.id} />
      </Box>
      {(answerSet.law_based_answer.answer || answerSet.court_decision_based_answer.answer) && (
        <Paper sx={{ p: 2, whiteSpace: 'pre-wrap', position: 'relative' }}>
          {answerSet.law_based_answer.answer && (
            <>
              <Stack direction="row" sx={{ justifyContent: 'space-between', mb: 2 }}>
                <Typography sx={{ fontWeight: 600 }}>Based on laws</Typography>
                <AdviserAnswerActions
                  answer={answerSet.law_based_answer}
                  answerType="ADVISOR_LAW_BASED_ANSWER"
                />
              </Stack>
              {answerSet.law_based_answer.answer}
              <Divider sx={{ my: 3 }} />
            </>
          )}
          {answerSet.court_decision_based_answer.answer && (
            <>
              <Stack direction="row" sx={{ justifyContent: 'space-between', mb: 2 }}>
                <Typography sx={{ fontWeight: 600 }}>Based on court decisions</Typography>
                <AdviserAnswerActions
                  answer={answerSet.court_decision_based_answer}
                  answerType="ADVISOR_COURT_DECISION_BASED_ANSWER"
                />
              </Stack>
              {answerSet.court_decision_based_answer.answer}
            </>
          )}
        </Paper>
      )}
      {laws?.length > 0 && (
        <Accordion className={classes.accordion} disableGutters elevation={0} square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography sx={{ fontWeight: 600 }}>Laws</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 0 }}>
            {renderLawSections()}
          </AccordionDetails>
        </Accordion>
      )}
      {answerSet.court_decision_based_answer.court_decisions?.length > 0 && (
        <Accordion className={classes.accordion} disableGutters elevation={0} square defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography sx={{ fontWeight: 600 }}>Court decisions</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ px: 0 }}>
            {renderDecisions()}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default AdvisorAnswer;
