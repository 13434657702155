import {
  all, put, takeLatest,
} from 'redux-saga/effects';
import { NotificationManager as NM } from 'react-notifications';

import { actions } from 'redux/decision/decisionReducer';
import { setIsGlobalLoading } from 'redux/main/mainReducer';
import request from '../utils/sagas/request';

export function* getDecisionFlow({ payload: { id, selectedParagraphIds, summaryLang } }) {
  yield put(setIsGlobalLoading(true));
  try {
    const { data } = yield request({
      method: 'get',
      url: `/finhound-service/court-decisions/${id}`,
    });

    yield put(actions.getDecisionSuccess({ data, selectedParagraphIds, summaryLang }));
  } catch (e) {
    NM.error('Something went wrong');
  }
  yield put(setIsGlobalLoading(false));
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getDecisionRequest.type, getDecisionFlow),
  ]);
}
