import { all, fork } from 'redux-saga/effects';

import search from './search/searchSaga';
import decision from './decision/decisionSaga';
import courtList from './facets/facetsSaga';
import submittedFeedbacks from './submittedFeedbacks/submittedFeedbacksSaga';

export default function* root() {
  yield all([
    fork(search),
    fork(decision),
    fork(courtList),
    fork(submittedFeedbacks),
  ]);
}
