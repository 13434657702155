import React from 'react';

import AdminPageWrapper from 'components/AdminPageWrapper';

import AsyncAnswerDebug from './AsyncAnswerDebug';

const AsyncAnswerDebugPage = () => (
  <AdminPageWrapper>
    <AsyncAnswerDebug />
  </AdminPageWrapper>
);

export default AsyncAnswerDebugPage;
