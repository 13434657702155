import React, { useState } from 'react';

import {
  Button, Stepper, Step, StepLabel, StepContent, Divider, Stack,
} from '@mui/material';

import { defaultConfig, getAnswersSettings } from 'utils/answersSettings';

import getQuestionsFromSheet from './utils/getQuestionsFromSheet';
import getConfigsFromSheet from './utils/getConfigsFromSheet';
import isConfigsValid from './utils/isConfigsValid';
import setConfigSheetColumnsKeys from './utils/setConfigSheetColumnsKeys';
import setConfigSheetValues from './utils/setConfigSheetValues';
import downloadWorkbook from './utils/downloadWorkbook';
import setConfigSheetStyles from './utils/setConfigSheetStyles';

import AnswerSetTestBenchDialog from './AnswerSetTestBenchDialog';

const ExcelJS = require('exceljs');

const TestBenchAnswerSetsTabContent = () => {
  const [requestsData, setRequestsData] = useState(null);

  const handleFilesInput = async (e) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const arrayBuffer = event.target.result;
      const buffer = new Uint8Array(arrayBuffer);

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);

      const configs = getConfigsFromSheet(workbook.getWorksheet(2));

      if (isConfigsValid(configs)) {
        const questions = getQuestionsFromSheet(workbook.getWorksheet(1));
        workbook.getWorksheet(1).spliceColumns(3, 1000);

        setRequestsData({ questions, configs, workbook });
      }
    };

    reader.readAsArrayBuffer(e.target.files[0]);

    e.target.value = '';
  };

  const downloadTemplateWithConfig = async (config) => {
    const response = await fetch(`${process.env.PUBLIC_URL}/Test bench answer sets.xlsx`);
    const arrayBuffer = await response.arrayBuffer();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(arrayBuffer);

    setConfigSheetColumnsKeys(workbook.getWorksheet(2));
    setConfigSheetValues(workbook.getWorksheet(2), config);
    setConfigSheetStyles(workbook.getWorksheet(2));
    downloadWorkbook(workbook, 'Test bench answer sets.xlsx');
  };

  const handleDownloadTemplateWithDefaultConfig = () => {
    downloadTemplateWithConfig(defaultConfig);
  };

  const handleDownloadTemplateWithMyConfig = () => {
    downloadTemplateWithConfig(getAnswersSettings());
  };

  return (
    <>
      <Stepper orientation="vertical">
        <Step active>
          <StepLabel>Download a template</StepLabel>
          <StepContent>
            <Stack
              direction="row"
              divider={(
                <Divider orientation="vertical" flexItem>
                  or
                </Divider>
              )}
              spacing={2}
            >
              <Button
                variant="contained"
                onClick={handleDownloadTemplateWithMyConfig}
              >
                Download template with default config
              </Button>
              <Button
                variant="contained"
                onClick={handleDownloadTemplateWithDefaultConfig}
              >
                Download template with my config
              </Button>
            </Stack>
          </StepContent>
        </Step>
        <Step active>
          <StepLabel>Fill in the template</StepLabel>
        </Step>
        <Step active>
          <StepLabel>Upload the modified file</StepLabel>
          <StepContent>
            <Button
              variant="contained"
              component="label"
            >
              Upload
              <input
                type="file"
                accept=".xlsx"
                hidden
                onChange={handleFilesInput}
              />
            </Button>
          </StepContent>
        </Step>
      </Stepper>
      {requestsData && (
        <AnswerSetTestBenchDialog
          requestsData={requestsData}
          onClose={() => setRequestsData(null)}
        />
      )}
    </>
  );
};

export default TestBenchAnswerSetsTabContent;
