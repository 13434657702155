import React, { useState, useMemo } from 'react';

import { Dialog } from 'components/UIKit';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NotificationManager as NM } from 'react-notifications';

import { JsonView, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import {
  LinearProgress, Switch, FormControlLabel, TextField,
} from '@mui/material';

import { makeAnswerSetTestBenchRequests } from './utils/makeAnswerSetTestBenchRequests';

import downloadWorkbook from '../utils/downloadWorkbook';

function getCurrentDateInYYYYMMDD() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}

const setCellStyle = (cell, style) => {
  // eslint-disable-next-line no-param-reassign
  cell.style = {
    ...cell.style,
    ...style,
  };
};

function addLeftBorderToColumn(worksheet, columnIndex) {
  worksheet.eachRow((row, rowNum) => {
    const cell = worksheet.getRow(rowNum).getCell(columnIndex);

    setCellStyle(cell, { border: { left: { style: 'medium' } } });
  });
}

const AnswerSetTestBenchDialog = ({ requestsData: { questions, configs, workbook }, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isParallelExecution, setIsParallelExecution] = useState(true);
  const [maxParallelRequests, setMaxParallelRequests] = useState('10');
  const [isAsync, setIsAsync] = useState(true);
  const [successRequestCount, setSuccessRequestCount] = useState(0);
  const [failedRequestCount, setFailedRequestCount] = useState(0);

  const requests = useMemo(() => questions.map((question) => configs.map(({ id, ...config }) => ({
    question,
    ...config,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }))), []);

  const handleSubmit = async () => {
    setIsLoading(true);

    const sheet = workbook.getWorksheet(1);

    const firstRow = sheet.getRow(1);
    const secondRow = sheet.getRow(2);

    for (let i = 3; i < 100; i += 1) {
      sheet.getColumn(i).width = 35;
    }

    configs.forEach((config, index) => {
      const columnsCount = isAsync ? 8 : 3;

      const startColNum = 3 + (columnsCount * index);

      try {
        sheet.mergeCells(0, startColNum, 0, startColNum + columnsCount - 1);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }

      firstRow.getCell(startColNum).value = `Config ${config.id}`;

      setCellStyle(firstRow.getCell(startColNum), { alignment: { vertical: 'middle', horizontal: 'center' } });

      if (isAsync) {
        secondRow.getCell(startColNum).value = 'Link';
        secondRow.getCell(startColNum + 1).value = 'Final Answer';
        secondRow.getCell(startColNum + 2).value = 'Law Answer 1';
        secondRow.getCell(startColNum + 3).value = 'Law Answer 2';
        secondRow.getCell(startColNum + 4).value = 'Law Answer 3';
        secondRow.getCell(startColNum + 5).value = 'Court Answer 1';
        secondRow.getCell(startColNum + 6).value = 'Court Answer 2';
        secondRow.getCell(startColNum + 7).value = 'Court Answer 3';
      } else {
        secondRow.getCell(startColNum).value = 'Link';
        secondRow.getCell(startColNum + 1).value = 'Court Answer';
        secondRow.getCell(startColNum + 2).value = 'Law Answer';
      }

      addLeftBorderToColumn(sheet, startColNum);
    });

    let withErrors = false;

    const results = await makeAnswerSetTestBenchRequests(
      requests,
      isParallelExecution ? Number(maxParallelRequests) : 1,
      isAsync,
      () => setSuccessRequestCount((n) => n + 1),
      () => setFailedRequestCount((n) => n + 1),
    );

    results.forEach((group, groupIndex) => {
      const row = sheet.getRow(3 + groupIndex);

      group.forEach((answer, answerIndex) => {
        const startColNum = 3 + (answerIndex * 3);

        if (!answer) {
          withErrors = true;

          const setError = (colNum) => {
            row.getCell(colNum).value = 'error';
            setCellStyle(row.getCell(colNum), {
              font: {
                color: { argb: 'FFFF0000' },
              },
            });
          };

          [startColNum, startColNum + 1, startColNum + 2].forEach(setError);

          return;
        }

        if (!isAsync) {
          row.getCell(startColNum + 1).value = answer.law_based_answer?.answer || 'No answer';
        } else if (answer.law_based_answers) {
          row.getCell(startColNum + 1).value = answer.summary || '';
          row.getCell(startColNum + 2).value = answer.law_based_answers?.[0]?.answer || '';
          row.getCell(startColNum + 3).value = answer.law_based_answers?.[1]?.answer || '';
          row.getCell(startColNum + 4).value = answer.law_based_answers?.[2]?.answer || '';
          row.getCell(startColNum + 5).value = answer.court_decision_based_answers?.[0]?.answer || '';
          row.getCell(startColNum + 6).value = answer.court_decision_based_answers?.[1]?.answer || '';
          row.getCell(startColNum + 7).value = answer.court_decision_based_answers?.[2]?.answer || '';
        }

        const link = `https://app.finhound.de/${isAsync ? 'advisor-experimental' : 'advisor'}?a=${answer.id}`;

        setCellStyle(row.getCell(startColNum), {
          font: {
            color: { theme: 10 },
            underline: true,
          },
        });

        row.getCell(startColNum).value = {
          text: link,
          hyperlink: link,
        };
      });
    });

    try {
      // eslint-disable-next-line no-param-reassign
      workbook.views[0] = {
        ...workbook.views[0],
        activeTab: 0,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    await downloadWorkbook(workbook, `Answer set test bench result ${getCurrentDateInYYYYMMDD()}.xlsx`);

    if (withErrors) {
      NM.error('Some of the requests were failed');
    } else {
      NM.success('Success');
    }

    setIsLoading(false);

    onClose();
  };

  const formValid = !isParallelExecution || maxParallelRequests;

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {`Test bench requests (configs: ${configs.length}, questions: ${questions.length})`}
      </DialogTitle>
      <DialogContent>
        {isLoading && <LinearProgress />}
        {isLoading && (
          <div>
            Success:
            {' '}
            {successRequestCount}
            ; Failure:
            {' '}
            {failedRequestCount}
          </div>
        )}
        <FormControlLabel
          control={<Switch checked={isParallelExecution} onChange={(e) => setIsParallelExecution(e.target.checked)} />}
          label="Parallel Execution"
        />
        <FormControlLabel
          control={<Switch checked={isAsync} onChange={(e) => setIsAsync(e.target.checked)} />}
          label="Async answers"
        />
        {isParallelExecution && (
          <TextField
            sx={{ display: 'flex', mb: 2 }}
            label="Max parallel requests"
            type="number"
            variant="standard"
            value={maxParallelRequests}
            onChange={(e) => setMaxParallelRequests(e.target.value)}
          />
        )}
        <JsonView data={requests} style={defaultStyles} shouldInitiallyExpand={(level) => level < 2} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button variant="contained" disabled={isLoading || !formValid} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AnswerSetTestBenchDialog;
