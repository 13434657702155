import { useState, useEffect } from 'react';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import {
  hitSectionParagraphs,
  hitAttachmentParagraphs,
  getSectionParagraphs,
} from './advisorAnswerLawUtils';

async function fetchLawById(id) {
  try {
    const response = await request({
      method: 'get',
      url: `/finhound-service/laws/${id}`,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error');
  }
}

const useAdviserAnswerLawSections = (lawsParam) => {
  const [itemsState, setItems] = useState(null);

  const fetchCourtDecisions = async () => {
    try {
      const laws = await Promise.all(lawsParam.map(({ id }) => fetchLawById(id)));

      const items = [];

      lawsParam.forEach((law, index) => {
        const lawData = laws[index];

        if (law.attachments.length > 0) {
          law.attachments.forEach((attachment) => {
            const attachmentData = lawData.attachments.find(({ id }) => id === attachment.id);

            attachmentData.section_ids.forEach(((id) => {
              if (!law.sections.find((section) => section.id === id)) {
                law.sections.push({ id, paragraph_ids: [], additions: [] });
              }
            }));
          });
        }

        law.sections.forEach((section) => {
          const sectionData = lawData.sections.find(({ id }) => id === section.id);
          // eslint-disable-next-line no-param-reassign
          section.data = sectionData;

          hitSectionParagraphs(section, sectionData);
        });

        if (law.attachments.length > 0) {
          const attachments = law.attachments.map((attachment) => {
            const attachmentData = lawData.attachments.find(({ id }) => id === attachment.id);

            hitAttachmentParagraphs(attachment, attachmentData);

            return attachmentData;
          });

          law.sections.sort((a, b) => a.data.index - b.data.index);

          items.push({
            law: {
              title: lawData.title,
              link: lawData.link,
            },
            sections: law.sections.map((section) => ({
              ...section.data,
              paragraphs: getSectionParagraphs(section.data),
            })),
            attachments,
          });
        } else {
          law.sections.forEach((section) => {
            items.push({
              law: {
                title: lawData.title,
                link: lawData.link,
              },
              sections: [
                {
                  ...section.data,
                  paragraphs: getSectionParagraphs(section.data),
                },
              ],
            });
          });
        }
      });

      setItems(items);
    } catch (e) {
      NM.error('Something went wrong');
    }
  };

  useEffect(() => {
    if (lawsParam?.length > 0) {
      fetchCourtDecisions();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawsParam?.length]);

  return {
    items: itemsState,
  };
};

export default useAdviserAnswerLawSections;
