import React from 'react';

import AdvisorPageContent from './AdvisorAsyncPageContent';

import useAsyncAnswer from './hooks/useAdvisorAsyncAnswer';

import useLaws from './hooks/useLaws';
import useDecisions from './hooks/useDecisions';
import getSourceMap from './getSourceMap';

const AdvisorAsyncPage = () => {
  const { isSavedAnswerLoading, answerSet, onSubmit } = useAsyncAnswer();
  const laws = useLaws(answerSet);
  const decisions = useDecisions(answerSet);

  return (
    <AdvisorPageContent
      pageTitle="Advisor experimental"
      isLoading={isSavedAnswerLoading}
      answerSet={answerSet}
      laws={laws}
      decisions={decisions}
      sourceMap={getSourceMap(laws, decisions)}
      onSubmit={onSubmit}
    />
  );
};

export default AdvisorAsyncPage;
