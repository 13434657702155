import React, { useState } from 'react';

import {
  IconButton, DialogTitle, DialogContent, Stack,
} from '@mui/material';
import { Dialog } from 'components/UIKit';

import InfoIcon from '@mui/icons-material/Info';
import SubjectReactions from 'components/SubjectReactions';

import { useIsAdmin } from 'hooks';

const AdviserAnswerActions = ({ answer, answerType }) => {
  const [isDialogOpened, setDialogOpened] = useState(false);
  const isAdmin = useIsAdmin();

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  return (
    <Stack direction="row" spacing={1} sx={{ mt: '-10px', mr: '-15px', alignItems: 'baseline' }}>
      <SubjectReactions subjectId={answer.id} subjectType={answerType} />
      {isAdmin && (
        <IconButton size="small" onClick={() => setDialogOpened(true)}>
          <InfoIcon />
        </IconButton>
      )}
      {isDialogOpened && (
        <Dialog open fullWidth maxWidth="md" withCloseButton onClose={handleDialogClose}>
          <DialogTitle>Prompt</DialogTitle>
          <DialogContent sx={{ whiteSpace: 'pre-wrap' }}>
            {answer.prompt.messages[0].content}
          </DialogContent>
        </Dialog>
      )}
    </Stack>
  );
};

export default AdviserAnswerActions;
