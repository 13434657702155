import React, { useState, Fragment } from 'react';

import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import AdvisorSettingsDialog from './AdvisorSettingsDialog';

function SettingsButton() {
  const [isDialogOpened, setDialogOpened] = useState(false);

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  return (
    <>
      <IconButton
        sx={{ mt: '7px' }}
        onClick={() => setDialogOpened(true)}
      >
        <SettingsIcon />
      </IconButton>
      <AdvisorSettingsDialog open={isDialogOpened} onClose={handleDialogClose} />
    </>
  );
}

export default SettingsButton;
