import React, { useState } from 'react';

import {
  LinearProgress,
} from '@mui/material';

import { useDidMount } from 'hooks';

import request from 'api/request';

import { NotificationManager as NM } from 'react-notifications';

import LawSectionCard from 'components/LawSectionCard';

import useStyles from './LawPreview.styles';

const LawPreview = ({ lawId }) => {
  const classes = useStyles();

  const [law, setLaw] = useState(null);

  const loadLaw = async () => {
    try {
      const { data } = await request({
        method: 'get',
        url: `/finhound-service/laws/${lawId}`,
        data: {
          offset: 0,
          limit: 100,
        },
      });

      setLaw(data);
    } catch {
      NM.error('Something went wrong');
    }
  };

  useDidMount(() => {
    loadLaw();
  });

  if (!law) {
    return <LinearProgress />;
  }

  return (
    <LawSectionCard law={law} {...law} paragraphTextClassName={classes.paragraph} />
  );
};

export default LawPreview;
