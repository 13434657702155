import React, { useState } from 'react';

import { Paper } from '@mui/material';

import AppSearchBar from 'components/UIKit/SearchBar';
import SettingsButton from './SettingsButton';

function SearchBar({
  className, defaultValue, onSearch, ...otherProps
}) {
  const [value, setValue] = useState(defaultValue);

  const handleInputChange = (e, newInputValue) => {
    setValue(newInputValue);
  };

  const handleClear = () => {
    setValue('');
  };

  const handleKeyDown = (e) => {
    if ((e.keyCode === 13 || e.code === 'Enter') && !e.shiftKey) {
      e.target.blur();
      e.preventDefault();
      onSearch(value);
    } else if (e.keyCode === 27 || e.code === 'Escape') {
      setValue('');
    }
  };

  return (
    <Paper
      className={className}
      sx={{ display: 'flex', alignItems: 'flex-start' }}
    >
      <AppSearchBar
        sx={{ flexGrow: 1 }}
        {...otherProps}
        value={value}
        label="What is your question?"
        placeholder=""
        textFieldProps={{
          multiline: true,
          minRows: 2,
          maxRows: 6,
          sx: { minHeight: 79 },
        }}
        onInputChange={handleInputChange}
        onClear={handleClear}
        onKeyDown={handleKeyDown}
      />
      <SettingsButton />
    </Paper>
  );
}

SearchBar.defaultProps = {
  defaultValue: '',
  onSearch: () => {},
};

export default SearchBar;
