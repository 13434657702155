/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Dialog } from 'components/UIKit';

import SearchResultsCard from 'components/SearchResultsCard';
import LawSectionCard from 'components/LawSectionCard';

import useStyles from './SourceReference.styles';

const SourceReference = ({ referenceId, source }) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const renderContent = () => {
    if (source.type === 'LAW') {
      return <LawSectionCard {...source.data} />;
    }

    return (
      <SearchResultsCard
        headerProps={{ withReactions: false }}
        searchResult={source.data}
        summaryDefaultExpanded
      />
    );
  };

  return (
    <>
      <span
        className={classes.root}
        style={{ color: source.type === 'LAW' ? '#008394' : '#8f9a27' }}
        onClick={() => setIsDialogOpen(true)}
      >
        [
        {referenceId}
        ]
      </span>
      <Dialog open={isDialogOpen} fullWidth maxWidth="md" withCloseButton onClose={() => setIsDialogOpen(false)}>
        {renderContent()}
      </Dialog>
    </>
  );
};

export default SourceReference;
