/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import {
  Typography, Paper,
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { alpha } from '@mui/material/styles';

import SummaryAccordion from 'components/SummaryAccordion';

import LawSectionCardHeader from './LawSectionCardHeader';
import LawCardParagraph from './LawCardParagraph';

import useStyles from './LawSectionCard.styles';

const LawSectionCard = ({
  law,
  sections,
  attachments = [],
  footer,
  paragraphTextClassName,
}) => {
  const classes = useStyles();

  const handleTitleClick = (e) => {
    e.preventDefault();
  };

  const renderParagraph = ({ component, paragraph }) => (
    <LawCardParagraph
      key={paragraph.id}
      component={component}
      paragraph={paragraph}
      paragraphTextClassName={paragraphTextClassName}
    />
  );

  const renderSectionParagraph = (paragraph, index) => {
    if (paragraph.items) {
      return (
        <ol style={{ marginTop: index === 0 ? 0 : 16 }} start={paragraph.items[0].number}>
          {paragraph.items.map((item) => renderParagraph({ component: 'li', paragraph: item }))}
        </ol>
      );
    }

    return renderParagraph({ paragraph });
  };

  return (
    <Paper className={classes.root}>
      <LawSectionCardHeader
        title={law.title}
        link={law.link}
        onTitleClick={handleTitleClick}
      />
      {law.summary && (
        <SummaryAccordion
          defaultExpanded={false}
          summaryEn={law.summary.en || law.summary}
          summaryDe={law.summary.de || law.summary_de}
        />
      )}
      <div>
        {sections.map(({
          id, labels, paragraphs, additions,
        }) => (
          <div className={classes.section} key={id}>
            <div style={{ display: 'flex' }}>
              <div style={{ flexShrink: 0, marginRight: 16, color: 'rgb(102, 102, 102)' }}>
                {labels.map((label) => (
                  <div key={label} style={{ marginBottom: 4 }}>
                    {label}
                  </div>
                ))}
              </div>
              <div style={{ flexGrow: 1 }}>
                {paragraphs.map((paragraph, index) => (
                // eslint-disable-next-line react/no-array-index-key
                  <Fragment key={index}>
                    {renderSectionParagraph(paragraph, index)}
                  </Fragment>
                ))}
              </div>
            </div>
            {additions.map((addition) => (
              <Accordion key={addition.id} className={classes.accordion}>
                <AccordionSummary
                  sx={{
                    background: (theme) => (addition.hit
                      ? alpha(theme.palette.primary.light, 0.2)
                      : undefined
                    ),
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.accordionTitle}>{addition.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {addition.paragraphs.map((paragraph) => renderParagraph({ paragraph }))}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        ))}
      </div>
      {attachments.length > 0 && (
        <div className={classes.attachments}>
          {attachments.map((attachment) => (
            <Accordion key={attachment.id} className={classes.accordion}>
              <AccordionSummary
                sx={{
                  background: (theme) => alpha(theme.palette.primary.light, 0.2),
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography className={classes.accordionTitle}>{attachment.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {attachment.paragraphs.map((paragraph) => renderParagraph({ paragraph }))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
      {footer}
    </Paper>
  );
};

export default LawSectionCard;
