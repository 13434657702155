import React from 'react';

import AdminPageWrapper from 'components/AdminPageWrapper';
import SearchList from './SearchList';

const HomePage = () => (
  <AdminPageWrapper>
    <SearchList />
  </AdminPageWrapper>
);

export default HomePage;
