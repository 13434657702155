/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getParagraphTypes = () => {
  const item = localStorage.getItem('searchSettings.paragraphTypes');

  if (item) {
    return JSON.parse(item);
  }

  return ['Title', 'SummaryDE'];
};

const getNumberFromLocalStorage = (key, defaultValue) => {
  const item = localStorage.getItem(key);

  if (!item) {
    return defaultValue;
  }

  return Number(item);
};

const getIsMongoSearch = () => {
  const item = localStorage.getItem('searchSettings.isMongoVectorSearch');

  if (!item) {
    return true;
  }

  return item === '1';
};

const getInitialState = () => ({
  searchResult: null,
  courtIds: [],
  isFiltersOpen: false,
  isSavedSearchLoadingError: false,
  searchTerm: '',
  isInitialized: false,
  settings: {
    paragraphTypes: getParagraphTypes(),
    mongoCutOff: getNumberFromLocalStorage('searchSettings.mongoCutOff', 0.884),
    pineconeCutOff: getNumberFromLocalStorage('searchSettings.pineconeCutOff', 0.78),
    hideLowScoreResults: localStorage.getItem('searchSettings.hideLowScoreResults') === '1',
    isMongoSearch: getIsMongoSearch(),
  },
});

export const slice = createSlice({
  name: 'search',
  initialState: getInitialState(),
  reducers: {
    init: () => {},
    initComplete: (state) => {
      state.isInitialized = true;
    },
    searchRequest: (state, { payload: { searchTerm } }) => {
      state.searchTerm = searchTerm;
    },
    searchSuccess: (state, { payload: { data } }) => {
      state.searchResult = data;
    },
    toggleCourt: (state, { payload: { id, value } }) => {
      if (value) {
        state.courtIds.push(id);
      } else {
        state.courtIds = state.courtIds.filter((courtId) => courtId !== id);
      }
    },
    setCourtIds: (state, { payload: { value } }) => {
      state.courtIds = value;
    },
    setIsFiltersOpen: (state, { payload: { value } }) => {
      state.isFiltersOpen = value;
    },
    getSearchResultSuccess: (state, { payload: { data } }) => {
      state.searchResult = data;
      state.courtIds = data.search_request.court_ids || [];
      state.searchTerm = data.search_request.search_term;
      state.settings.paragraphTypes = data.search_request.paragraph_types;
      state.settings.isMongoSearch = data.search_request.mongo_search;
    },
    setSettings: (state, {
      payload: {
        paragraphTypes, mongoCutOff, pineconeCutOff, hideLowScoreResults, isMongoSearch,
      },
    }) => {
      state.settings = {
        paragraphTypes,
        mongoCutOff,
        pineconeCutOff,
        hideLowScoreResults,
        isMongoSearch,
      };
    },
    resetSettings: (state) => {
      localStorage.getItem('searchSettings.paragraphTypes');

      state.settings = getInitialState().settings;
    },
    searchByDecisionIdRequest: () => {},
    searchByDecisionIdSuccess: (state, { payload: { data: { id, paragraphs, ...rest } } }) => {
      state.searchResult = {
        search_request: {},
        items: [{
          ...rest,
          court_decision_id: id,
          paragraphs: [],
        }],
      };
    },
    reset: () => getInitialState(),
  },
});

export const { actions } = slice;

export default slice.reducer;
