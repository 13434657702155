import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

let tokenGenerator = null;

export const setTokenGenerator = (value) => { tokenGenerator = value; };

const axios = setupCache(Axios, {
  cacheTakeover: false,
});

const request = async ({
  method,
  data,
  headers,
  cache = false,
  ...rest
}) => {
  const token = tokenGenerator ? await tokenGenerator() : null;
  const res = await axios({
    method,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    baseURL: 'https://finhound-common-api-gw-7mcnv7e9.ew.gateway.dev',
    cache,
    ...rest,
  });

  return res;
};

export default request;
