const downloadWorkbook = async (workbook, filename) => {
  const modifiedBuffer = await workbook.xlsx.writeBuffer();
  // eslint-disable-next-line max-len
  const blob = new Blob([modifiedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

  const blobUrl = URL.createObjectURL(blob);

  const downloadLink = document.createElement('a');
  downloadLink.href = blobUrl;
  downloadLink.download = filename;
  downloadLink.click();
};

export default downloadWorkbook;
