import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    color: '#000',
    borderRadius: 5,
    padding: 11,

    '&:not(:last-of-type)': {
      marginBottom: 16,
    },
  },
  header: {
    display: 'flex',
    paddingBottom: 8,
    position: 'relative',
  },
  courtLogo: {
    flexShrink: 0,
    width: 40,
    height: 45,
    marginRight: 10,
    marginTop: 4,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  caseNumber: {
    fontWeight: 700,
    borderRadius: 5,
    paddingRight: 120,
  },
  title: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 8,
  },
  date: {
    marginTop: 4,
    fontSize: 12,
    color: '#70757a',
  },
  paragraphText: {
    fontSize: 14,
    marginBottom: 8,
  },
  accordion: {
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
  },
  accordionTitle: {
    fontWeight: 500,
  },
  languageSwitcher: {
    marginLeft: 8,
  },
  attachments: {
    paddingTop: 8,
    marginTop: 8,
    borderTop: '1px dashed rgb(229, 234, 242)',
  },
  section: {
    marginTop: 32,

    '&:not(:last-of-type)': {
      paddingBottom: 16,
      borderBottom: '1px solid rgb(229, 234, 242)',
    },

    '&:first-of-type': {
      marginTop: 16,
    },
  },
});

export default useStyles;
