/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography, Link,
} from '@mui/material';

import FeedbackDialog from 'components/FeedbackDialog';

import { submittedFeedbacksSelectors as selectors } from 'redux/selectors';

const ResultFeedback = ({ sx, subjectId, subjectType }) => {
  const feedback = useSelector(selectors.feedbackSelector(subjectId));
  const isLoading = useSelector(selectors.isLoadingSelector);

  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const isSubmitted = Boolean(feedback);

  const handleClick = (e) => {
    e.preventDefault();
    setIsDialogOpened(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpened(false);
  };

  if (!subjectId) {
    return null;
  }

  return (
    <>
      <Typography
        sx={{ mb: '16px', ...sx }}
        style={{ visibility: isLoading ? 'hidden' : 'visible' }}
      >
        {isSubmitted ? (
          <>
            Thank you for
            {' '}
            <Link href="#" onClick={handleClick}>your feedback</Link>
          </>
        ) : (
          <>
            Thoughts on the results below?
            {' '}
            <Link href="#" onClick={handleClick}>Let us know!</Link>
          </>
        )}
      </Typography>
      <FeedbackDialog
        open={isDialogOpened}
        defaultRating={feedback?.rating}
        defaultComment={feedback?.comment}
        editMode={isSubmitted}
        subjectType={subjectType}
        subjectId={subjectId}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default ResultFeedback;
