import React, { useState, Fragment } from 'react';

import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';

import AdvisorConfigForm from 'components/AdvisorConfigForm';

const getConfig = (request) => ({
  ...request,
  courtDecisionRules: {
    ...request.court_decision_rules,
    mongoCutOffScore: request.court_decision_rules.cut_off_score,
    pineconeCutOffScore: request.court_decision_rules.cut_off_score,
    tempPromptTemplate: request.court_decision_rules.prompt_template,
  },
  lawRules: {
    ...request.law_rules,
    mongoCutOffScore: request.law_rules.cut_off_score,
    pineconeCutOffScore: request.law_rules.cut_off_score,
    tempPromptTemplate: request.law_rules.prompt_template,
  },
});

function ViewAsyncAnswerRequestConfig({ request }) {
  const [isDialogOpened, setDialogOpened] = useState(false);

  const handleDialogClose = () => {
    setDialogOpened(false);
  };

  return (
    <>
      <Button onClick={() => setDialogOpened(true)}>
        View Config
      </Button>
      {isDialogOpened && (
        <Dialog
          PaperProps={{
            sx: {
              height: '100%',
            },
          }}
          open
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Config</DialogTitle>
          <DialogContent>
            <AdvisorConfigForm defaultValues={getConfig(request)} disabled json={request} />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button
              type="submit"
              form="answers-config"
              color="warning"
            >
              Overwrite my config
            </Button>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default ViewAsyncAnswerRequestConfig;
