/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import { Typography } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';

import useStyles from './SummaryAccordion.styles';

const SummaryAccordion = ({
  defaultExpanded = true,
  summaryEn,
  summaryDe,
  onChange,
}) => {
  const classes = useStyles();
  const expendedSummaryRef = useRef(defaultExpanded);
  const [summaryLang, setSummaryLang] = useState('de');

  const handleSummaryLanguageChange = (event, newAlignment) => {
    if (expendedSummaryRef.current) {
      event.stopPropagation();
    }

    setSummaryLang(newAlignment);
    if (onChange) {
      onChange(newAlignment);
    }
  };

  const handleSummaryAccordionChange = (e, expended) => {
    expendedSummaryRef.current = expended;
  };

  const selectedSummary = summaryLang === 'en' ? summaryEn : summaryDe;

  return (
    <Accordion
      className={classes.root}
      defaultExpanded={defaultExpanded}
      onChange={handleSummaryAccordionChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.accordionTitle}>Summary</Typography>
        <LanguageSwitcher
          className={classes.languageSwitcher}
          value={summaryLang}
          onChange={handleSummaryLanguageChange}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Typography className={classes.paragraphText}>
          {selectedSummary || 'Summary will be here'}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default SummaryAccordion;
