/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { IconButton, Stack } from '@mui/material';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

import FeedbackDialog from 'components/FeedbackDialog/FeedbackDialog';

import { submittedFeedbacksSelectors as selectors } from 'redux/selectors';

import useStyles from './SubjectReactions.styles';

const SubjectReactions = ({ className, subjectId, subjectType }) => {
  const classes = useStyles();

  const [isFeedbackDialogOpened, setIsFeedbackDialogOpened] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(null);

  const feedback = useSelector(selectors.feedbackSelector(subjectId));
  const isLoading = useSelector(selectors.isLoadingSelector);
  const feedbackRating = feedback ? feedback.rating : null;

  const handleLikeClick = () => {
    setIsFeedbackDialogOpened(true);
    setSelectedReaction('like');
  };

  const handleDislikeClick = () => {
    setIsFeedbackDialogOpened(true);
    setSelectedReaction('dislike');
  };

  const handleFeedbackDialogClose = () => {
    setIsFeedbackDialogOpened(false);
    setSelectedReaction(null);
  };

  if (!subjectId) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} className={className} style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
      <IconButton
        color={feedbackRating && feedbackRating >= 3 ? 'primary' : undefined}
        className={classes.iconButton}
        size="small"
        onClick={handleLikeClick}
      >
        <ThumbUpOffAltIcon />
      </IconButton>
      <IconButton
        color={feedbackRating && feedbackRating < 3 ? 'primary' : undefined}
        className={classes.iconButton}
        size="small"
        onClick={handleDislikeClick}
      >
        <ThumbDownOffAltIcon />
      </IconButton>
      {isFeedbackDialogOpened && (
        <FeedbackDialog
          open
          defaultRating={selectedReaction === 'like' ? 5 : 1}
          defaultComment={feedback?.comment}
          subjectType={subjectType}
          subjectId={subjectId}
          editMode={Boolean(feedback)}
          description="How do you feel about the result of your question?"
          onClose={handleFeedbackDialogClose}
        />
      )}
    </Stack>
  );
};

export default SubjectReactions;
