import React from 'react';

import AdminPageWrapper from 'components/AdminPageWrapper';
import AnswerSetList from './AsyncAnswerList';

const AnswerSetListPage = () => (
  <AdminPageWrapper>
    <AnswerSetList />
  </AdminPageWrapper>
);

export default AnswerSetListPage;
