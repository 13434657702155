const getQuestionsFromSheet = (sheet) => {
  const res = [];

  sheet.eachRow((row, i) => {
    if (i >= 2) {
      res.push({
        question: row.getCell('B').value,
        evidence: row.getCell('D').value,
      });
    }
  });

  return res;
};

export default getQuestionsFromSheet;
