import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  dialogPaper: {
    backgroundColor: '#e7ebf0',
    minHeight: 100,
  },
  feedback: {
    padding: '8px 0',

    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
  },
  searchResultFeedbackAccordion: {
    boxShadow: 'none',
  },
  accordionTitle: {
    fontWeight: 500,
  },
});

export default useStyles;
