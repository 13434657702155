import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    fontWeight: '600',
    cursor: 'pointer',
  },
});

export default useStyles;
