const setConfigSheetColumnsKeys = (sheet) => {
  // eslint-disable-next-line no-param-reassign
  sheet.columns = [
    { key: 'id' },
    { key: 'c.input-expansion' },
    { key: 'c.input-expansion-prompt-template' },
    { key: 'cdr.engine' },
    { key: 'cdr.model' },
    { key: 'cdr.prompt' },
    { key: 'cdr.cut-off' },
    { key: 'cdr.top-results' },
    { key: 'cdr.evidence-classification' },
    { key: 'cdr.evidence-classification-prompt' },
    { key: 'cdr.evidence-classification-cut-off' },
    { key: 'cdr.search-by.Title' },
    { key: 'cdr.search-by.Summary' },
    { key: 'cdr.search-by.SummaryDE' },
    { key: 'cdr.search-by.Thesis' },
    { key: 'cdr.search-by.Object' },
    { key: 'cdr.search-by.Tenor' },
    { key: 'cdr.search-by.Facts' },
    { key: 'cdr.search-by.Reasons' },
    { key: 'cdr.prompt-paragraph-types' },
    { key: 'cdr.prompt-paragraph-types.Summary' },
    { key: 'cdr.prompt-paragraph-types.SummaryDE' },
    { key: 'cdr.prompt-paragraph-types.Thesis' },
    { key: 'cdr.prompt-paragraph-types.Object' },
    { key: 'cdr.prompt-paragraph-types.Tenor' },
    { key: 'cdr.prompt-paragraph-types.Facts' },
    { key: 'cdr.prompt-paragraph-types.Reasons' },
    { key: 'lr.engine' },
    { key: 'lr.model' },
    { key: 'lr.prompt' },
    { key: 'lr.cut-off' },
    { key: 'lr.top-results' },
    { key: 'lr.evidence-classification' },
    { key: 'lr.evidence-classification-prompt' },
    { key: 'lr.evidence-classification-cut-off' },
    { key: 'lr.search-by.SECTION' },
    { key: 'lr.search-by.GUIDELINE' },
    { key: 'lr.search-by.INTERPRETATION' },
    { key: 'lr.search-by.EXEMPTION' },
    { key: 'lr.search-by.ATTACHMENT' },
    { key: 'lr.search-by.SUMMARY' },
    { key: 'lr.search-by.SUMMARY_DE' },
    { key: 'c.model' },
    { key: 'c.prompt' },
  ];
};

export default setConfigSheetColumnsKeys;
