import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  root: {
    display: 'flex',
  },
  iconButton: {
    height: 34,
  },
});

export default useStyles;
