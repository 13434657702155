import { createUseStyles } from 'react-jss';
import { media } from 'breakpoints';

const useStyles = createUseStyles({
  content: {
    [media.mobile]: {
      padding: 0,
    },
  },
});

export default useStyles;
