import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDidMount } from 'hooks';

import { NotificationManager as NM } from 'react-notifications';

import request from 'api/request';

import transformAnswerDebugLaws from './utils/transformAnswerDebugLaws';

async function fetchAnswerSetById(id) {
  try {
    const { data } = await request({
      method: 'get',
      url: `/finhound-service/async-answers/${id}`,
    });
    return data;
  } catch (error) {
    throw new Error('Error');
  }
}

async function fetchLawById(id) {
  try {
    const response = await request({
      method: 'get',
      url: `/finhound-service/laws/${id}`,
      cache: true,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error');
  }
}

const getMaxScore = (paragraphs) => {
  let res = 0;

  paragraphs.forEach((score) => {
    res = Math.max(res, score.evidence_score);
  });

  return res;
};

const useAnswerDebug = () => {
  const [answerInfo, setAnswerInfo] = useState(null);
  const { id: answerSetId } = useParams();

  const fetchAnswer = async () => {
    try {
      const answerSet = await fetchAnswerSetById(answerSetId);

      answerSet.score_processing_info.court_based_evidence_classifier_scores ||= [];
      answerSet.score_processing_info.court_based_search_result_scores ||= [];
      answerSet.score_processing_info.law_based_evidence_classifier_scores ||= [];
      answerSet.score_processing_info.law_based_search_result_scores ||= [];

      answerSet.score_processing_info.law_based_evidence_classifier_scores.forEach((score) => {
        // eslint-disable-next-line no-param-reassign
        const score2 = answerSet.score_processing_info.law_based_search_result_scores.find(({ id }) => id === score.id);

        score2.maxEvidenceScore = getMaxScore(score.paragraphs);
      });

      answerSet.score_processing_info.law_based_search_result_scores
        .sort((a, b) => b.maxEvidenceScore - a.maxEvidenceScore);

      const laws = await Promise.all(
        answerSet.score_processing_info.law_based_search_result_scores.map(({ id }) => fetchLawById(id)),
      );

      transformAnswerDebugLaws(laws, answerSet);

      setAnswerInfo({ answerSet, laws });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      NM.error('Something went wrong');
    }
  };

  useDidMount(() => {
    fetchAnswer();
  });

  return answerInfo;
};

export default useAnswerDebug;
