import React, { useState, useMemo } from 'react';

import { Dialog } from 'components/UIKit';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { NotificationManager as NM } from 'react-notifications';

import { JsonView, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import {
  LinearProgress,
} from '@mui/material';

import makeEvidenceClassificationTestBenchRequests from './utils/makeEvidenceClassificationTestBenchRequests';
import getRequestBody from './utils/getRequestBody';

function getCurrentDateInYYYYMMDD() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}

const LOCALSTORAGE_KEY = 'sdlfshdlkfjk';

const EvidenceClassificationTestBenchDialog = ({ requestsData: { questions, configs, workbook }, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState(localStorage.getItem(LOCALSTORAGE_KEY) || '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requests = useMemo(() => configs.map((config) => getRequestBody(config, questions)), []);

  const handleSubmit = async () => {
    localStorage.setItem(LOCALSTORAGE_KEY, apiKey);
    setIsLoading(true);

    const sheet = workbook.getWorksheet(1);

    const results = await makeEvidenceClassificationTestBenchRequests(requests, apiKey);

    results.forEach((resultItems, index) => {
      resultItems.forEach((score, resultItemIndex) => {
        const cell = sheet.getRow(2 + resultItemIndex).getCell(6 + index);

        cell.value = score;
        cell.font = { bold: false };
      });
    });

    try {
      // eslint-disable-next-line no-param-reassign
      workbook.views[0] = {
        ...workbook.views[0],
        activeTab: 0,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    const modifiedBuffer = await workbook.xlsx.writeBuffer();
    // eslint-disable-next-line max-len
    const blob = new Blob([modifiedBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const blobUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = `Evidence classification result ${getCurrentDateInYYYYMMDD()}.xlsx`;
    downloadLink.click();

    NM.success('Success');

    setIsLoading(false);

    onClose();
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {`Evidence classification requests (configs: ${configs.length}, questions: ${questions.length})`}
      </DialogTitle>
      <DialogContent>
        {isLoading && <LinearProgress />}
        <TextField
          sx={{ mb: 3 }}
          type="password"
          label="OpenAI api-key"
          variant="standard"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
        />
        <JsonView data={requests} style={defaultStyles} shouldInitiallyExpand={(level) => level < 2} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button variant="contained" disabled={isLoading} onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EvidenceClassificationTestBenchDialog;
