export function hitSectionParagraphs(section, sectionData) {
  section.paragraph_ids.forEach((paragraphId) => {
    // eslint-disable-next-line no-param-reassign
    sectionData.paragraphs.find(({ id }) => id === paragraphId).hit = true;
  });

  section.additions.forEach((addition) => {
    const additionData = sectionData.additions.find(({ id }) => id === addition.id);

    additionData.hit = true;

    addition.paragraph_ids.forEach((paragraphId) => {
      additionData.paragraphs.find((paragraph) => paragraph.id === paragraphId).hit = true;
    });
  });
}

export function hitAttachmentParagraphs(attachment, attachmentData) {
  attachment.paragraph_ids.forEach((paragraphId) => {
    // eslint-disable-next-line no-param-reassign
    attachmentData.paragraphs.find(({ id }) => id === paragraphId).hit = true;
  });
}

export function getSectionParagraphs(section) {
  const res = [];
  let items = [];

  section.paragraphs.forEach((paragraph) => {
    if (!paragraph.number) {
      if (items.length) {
        res.push({
          items,
        });
        items = [];
      }
      res.push(paragraph);
    } else {
      items.push(paragraph);
    }
  });

  if (items.length) {
    res.push({
      items,
    });
  }

  return res;
}
