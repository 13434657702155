import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Helmet from 'react-helmet';

import { mainSelectors } from 'redux/selectors';

import {
  AppBar, Box, Toolbar, Typography, LinearProgress, Divider,
} from '@mui/material';

import AvatarMenu from 'components/AvatarMenu';
import AppsMenu from 'components/AppsMenu';

import FiltersMobileDialog from './FiltersMobileDialog/FiltersMobileDialog';

import useStyles from './AppWrapperMobile.styles';

function AppWrapperMobile({
  children, headerContent, isFiltersOpen, filters, contentClassName, title, actions, onFiltersClose,
}) {
  const classes = useStyles();
  const isLoading = useSelector(mainSelectors.isLoadingSelector);

  return (
    <div className={classes.root}>
      <div className={classes.progressWrapper}>
        {isLoading && <LinearProgress className={classes.progress} />}
      </div>
      <AppBar className={classes.appBar} position="static">
        <Toolbar className={classes.toolbar}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AppsMenu />
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                lineHeight: '20px',
              }}
            >
              <div>FINHOUND</div>
              {title && <div style={{ fontSize: '16px', letterSpacing: '.1rem' }}>{title}</div>}
            </Typography>
            {actions}
            <Box sx={{ ml: 'auto', pl: 4 }}>
              <AvatarMenu />
            </Box>
          </Box>
          {headerContent && (
            <>
              <Divider sx={{
                mt: '8px', mb: '8px', backgroundColor: 'white', opacity: 0.5,
              }}
              />
              <Box>
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                  {headerContent}
                </Box>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <div className={cx(classes.contentWrapper)}>
        <div className={cx(classes.content, contentClassName)}>
          {children}
        </div>
      </div>
      <Helmet><title>{title ? `FINHOUND: ${title}` : 'FINHOUND'}</title></Helmet>
      <FiltersMobileDialog isOpen={isFiltersOpen} filters={filters} onClose={onFiltersClose} />
    </div>
  );
}

export default AppWrapperMobile;
