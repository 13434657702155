import React, { useState } from 'react';
import { format } from 'date-fns';

import {
  Paper, TableContainer, Table, TableHead, TableRow, Select, MenuItem,
  TableCell, TableBody, TablePagination, Switch, FormControlLabel, TextField, IconButton,
} from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import usePaginatedList from './hooks/useAnswerSetList';

const AnswerSetList = () => {
  const {
    items,
    totalItems,
    page,
    email,
    rowsPerPage,
    period,
    onPageChange,
    onEmailChange,
    onWithFeedbacksChange,
    onPeriodChange,
    onSubmit,
  } = usePaginatedList();

  // eslint-disable-next-line no-unused-vars
  const [isSearchResultDialogOpened, setIsSearchResultDialogOpened] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchResultDialogData, setSearchResultDialogData] = useState(null);

  if (!items) {
    return null;
  }

  const handleRowClick = (item) => {
    setIsSearchResultDialogOpened(true);
    setSearchResultDialogData({ id: item.id });
  };

  const handlePageChange = (event, newValue) => {
    onPageChange(newValue);
  };

  const renderFeedback = (feedback) => {
    if (!feedback) {
      return null;
    }

    if (feedback[0] === feedback[1]) {
      return feedback[0];
    }

    return `${feedback[0]} - ${feedback[1]}`;
  };

  return (
    <Paper>
      <div style={{ padding: '16px 16px 8px' }}>
        <FormControlLabel
          control={<Switch onChange={(e) => { onWithFeedbacksChange(e.target.checked); }} />}
          label="Only with feedbacks"
        />
        <TextField
          value={email}
          label="User email"
          size="small"
          onChange={(e) => onEmailChange(e.target.value)}
          onBlur={onSubmit}
          onKeyDown={(event) => { if (event.key === 'Enter') { onSubmit(); } }}
        />
        <Select
          sx={{ ml: 2 }}
          value={period}
          size="small"
          onChange={(e) => onPeriodChange(e.target.value)}
        >
          <MenuItem value="lastWeek">
            Last week
          </MenuItem>
          <MenuItem value="allTime">
            All time
          </MenuItem>
        </Select>
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 190 }}>Created</TableCell>
              <TableCell sx={{ width: 190 }}>User email</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Answer feedback</TableCell>
              <TableCell>Law based answer feedback</TableCell>
              <TableCell>Court decision based answer feedback</TableCell>
              <TableCell sx={{ width: 30 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id} hover sx={{ cursor: 'pointer' }} onClick={() => handleRowClick(item)}>
                <TableCell>{format(new Date(item.created), 'yyyy-MM-dd HH:mm')}</TableCell>
                <TableCell>{item.creator_email || 'None'}</TableCell>
                <TableCell>{item?.request?.question}</TableCell>
                <TableCell>{renderFeedback(item.feedbacks)}</TableCell>
                <TableCell>{renderFeedback(item.lawFeedbacks)}</TableCell>
                <TableCell>{renderFeedback(item.courtFeedbacks)}</TableCell>
                <TableCell>
                  <IconButton
                    component="a"
                    href={`/advisor/?a=${item.id}`}
                    target="_blank"
                    size="small"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <OpenInNewIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        rowsPerPage={rowsPerPage}
        component="div"
        count={totalItems}
        page={page}
        onPageChange={handlePageChange}
      />
    </Paper>
  );
};

export default AnswerSetList;
