/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cx from 'classnames';
import {
  Typography,
} from '@mui/material';

import { alpha } from '@mui/material/styles';

import { useIsAdmin } from 'hooks';

import useStyles from './LawCardParagraph.styles';

const getVectorColor = ({ approved0, approved1 }) => {
  if (approved1) {
    return '#00695f';
  }

  if (approved0) {
    return '#ff9800';
  }

  return '#aa2e25';
};

const LawCardParagraph = ({
  paragraph,
  component,
  paragraphTextClassName,
}) => {
  const classes = useStyles();
  const isAdmin = useIsAdmin();

  const { debugInfo } = paragraph;

  const renderDebugInfo = () => (
    <div style={{ display: 'flex', marginLeft: 20, textAlign: 'center' }}>
      <div
        style={{
          flexShrink: 0, width: 50, color: getVectorColor(debugInfo),
        }}
        title={debugInfo.db_score}
      >
        {debugInfo.db_score ? debugInfo.db_score.toFixed(2) : 'n/a'}
      </div>
      <div
        style={{
          flexShrink: 0, width: 50, color: debugInfo.approved2 ? '#00695f' : '#aa2e25',
        }}
        title={debugInfo.evidence_score}
      >
        {debugInfo.evidence_score ? debugInfo.evidence_score.toFixed(1) : 'n/a'}
      </div>
    </div>
  );

  return (
    <div className={cx(classes.root, paragraphTextClassName)}>
      <Typography
        key={paragraph.id}
        component={component}
        className={cx(classes.paragraphText, paragraphTextClassName)}
        sx={{
          background: (theme) => (paragraph.hit
            ? alpha(theme.palette.primary.light, 0.2)
            : undefined
          ),
        }}
      >
        {paragraph.text}
        {' '}
        {isAdmin && paragraph.hit?.db_score && paragraph.hit?.evidence_score
          ? `(${paragraph.hit.db_score.toFixed(3)}/${paragraph.hit.evidence_score.toFixed(1)})`
          : ''}
      </Typography>
      {debugInfo && renderDebugInfo()}
    </div>

  );
};

export default LawCardParagraph;
