import React from 'react';

import {
  Typography,
} from '@mui/material';

const statuses = {
  INPUT_EXPANSION: 'preparing for the search',
  QUESTION_VECTORIZATION: 'preparing for the search',
  COURT_DECISIONS_SEARCH: 'searching court decisions',
  LAWS_SEARCH: 'searching laws ',
  LAWS_EVIDENCE_CLASSIFICATION: 'checking found laws',
  LAWS_ENRICHMENT: 'enriching found laws',
};

const decodeStepName = (name) => {
  if (name.includes('LAWS_PROMPT_GENERATION')) {
    return 'preparing for laws based answer generation';
  }
  if (name.includes('LAWS_BASED_ANSWER_GENERATION')) {
    return 'generating laws based answers';
  }
  if (name.includes('COURT_DECISIONS_PROMPT_GENERATION')) {
    return 'preparing for court decisions based answer generation';
  }
  if (name.includes('COURT_DECISIONS_BASED_ANSWER_GENERATION')) {
    return 'generating court decisions based answers';
  }

  return statuses[name];
};

const AdvisorResultStatus = ({ answerSet }) => {
  const getStatus = () => {
    if (answerSet.isGeneratingSummary) {
      return 'generating a final summary';
    }

    if (answerSet.processing_steps.length === 0) {
      return statuses.QUESTION_VECTORIZATION;
    }

    const textStatuses = answerSet.processing_steps
      .filter(({ status }) => status === 'STARTED' || status === 'CREATED')
      .map(({ name }) => decodeStepName(name))
      .filter((value) => value);

    const res = [...new Set(textStatuses)];

    return res.join(' and ');
  };

  return (
    <Typography sx={{ mb: '16px', '&:first-letter': { textTransform: 'uppercase' } }}>
      {getStatus()}
      ...
    </Typography>
  );
};

export default AdvisorResultStatus;
