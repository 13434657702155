/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Typography, Link, IconButton,
} from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import useStyles from './LawSectionCardHeader.styles';

const LawSectionCardHeader = ({
  title,
  link,
  onTitleClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.caseNumberWrapper}>
          <Link href="#" underline="hover">
            <Typography className={classes.caseNumber} onClick={onTitleClick}>
              {title}
            </Typography>
          </Link>
          <IconButton
            className={classes.linkButton}
            component="a"
            href={link}
            target="_blank"
            size="small"
          >
            <OpenInNewIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default LawSectionCardHeader;
